import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Dialog, Stack, Typography } from "@mui/material";
import Sidebar from "../../components/sidebar/SideBar";
import InventoryComponents from "../../components/InventoryManagement/InventoryComponents";
import ProductCard from "../../components/InventoryManagement/ProductCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { toast } from "react-toastify";
import { useAppStore } from "../../appStore";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #7B029E",
  borderRadius: 1.5,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  p: 2,
  textAlign: "center",
};

function InventoryManagement() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { isVerified } = useAppStore();
  const [openDialog, setOpenDialog] = useState(false);

  const handleNavigate = () => {
    navigate("/addProducts");
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" width={"100%"} sx={{ p: 1 }}>
          <Box height={90} />
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2, ml: 1, color: "#7B029E" }}
          >
            My Inventory{" "}
          </Typography>

          <ProductCard />

          <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
            <Button
              variant="contained"
              onClick={handleNavigate}
              sx={{
                backgroundColor: "#7B029E",
                color: "#FFFFFF",
                textTransform: "capitalize",
                borderRadius: 3,
                "&:hover": {
                  background: "#7B029E",
                },
              }}
            >
              {" "}
              Add Products
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default InventoryManagement;

import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import moment from "moment/moment";

// const steps = [
//   { date: "AUG 10", description: "Deposit", amount: "€ 100.00" },
//   {
//     date: "AUG 10",
//     description: "Payment Euflora",
//     amount: "€ 250.00",
//     location: "Denver, CO",
//   },
//   { date: "JUL 22", description: "Deposit", amount: "€ 350.00" },
//   { date: "AUG 10", description: "Deposit", amount: "€ 100.00" },
//   {
//     date: "AUG 10",
//     description: "Payment Euflora",
//     amount: "€ 250.00",
//     location: "Denver, CO",
//   },
//   { date: "JUL 22", description: "Deposit", amount: "€ 350.00" },
// ];

const StepperComponent = () => {
  const [showAll, setShowAll] = useState(false);
  const [activities, setActivities] = useState([]);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const resetToInitial = () => {
    setShowAll(false);
  };

  const getStepColor = (status) => {
    switch (status) {
      case "requested":
        return "red";
      case "success":
        return "green";
      default:
        return "default";
    }
  };

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get(
        API_PROD + "/settlement/getMySettlements",
        {
          headers,
        }
      );
      console.log("activities", response?.data?.mySettlements);
      setActivities(response?.data?.mySettlements);
      // setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      // setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchActivities();
  }, []);

  return (
    <>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{ color: "#7B029E", fontWeight: "bold" }}
        >
          My Activity
        </Typography>
      </Box>
      {/* stepper */}
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Stepper
              activeStep={showAll ? activities.length : 0}
              orientation="vertical"
            >
              {activities
                .slice(0, showAll ? activities.length : 4)
                .map((step, index) => (
                  <Step
                    key={index}
                    completed={!showAll && index < step.length - 1}
                    sx={{
                      "& .MuiStepIcon-root": {
                        color: getStepColor(step.status),
                      },
                    }}
                  >
                    <StepLabel>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <div
                            style={{
                              color:
                                step?.status === "requested" ? "red" : "green",
                            }}
                          >
                            <strong>
                              {moment(step.createdAt).format("ll")}
                            </strong>
                            <br />
                            {step.status}
                            <br />
                            {}
                          </div>
                        </Grid>

                        <Grid item>
                          <div
                            style={{
                              color:
                                step?.status === "requested" ? "red" : "green",
                            }}
                          >
                            {step.amount}
                          </div>
                        </Grid>
                      </Grid>
                    </StepLabel>
                    <StepContent>
                      {step.location && <p>Location: {step.location}</p>}
                    </StepContent>
                  </Step>
                ))}
            </Stepper>
          </Grid>
          <Grid item sm={6}>
            <Box sx={{ textAlign: "center", mt: 14, position: "relative" }}>
              {showAll ? (
                <Typography
                  variant="subtitle1"
                  onClick={resetToInitial}
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                >
                  Show Initial
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  onClick={toggleShowAll}
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                >
                  See All Activity
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StepperComponent;

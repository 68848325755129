import { Box } from "@mui/material";
import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import AddProductComponent from "../../components/Product/AddProduct";
import EditProductComponent from "../../components/Product/EditProduct";

function EditProduct() {
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Box height={90} />

          <EditProductComponent />
        </Box>
      </Box>
    </div>
  );
}

export default EditProduct;

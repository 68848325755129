import { Box, Chip, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { API_PROD } from "../../apiUtils";
import moment from "moment/moment";
import Scrollbar from "react-scrollbars-custom";

function HistoryOrders() {
  const [orderHistory, setOrderHistory] = useState([]);
  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        API_PROD + "/order/getVendorOrderHistory",
        {
          headers,
        }
      );
      console.log("history", response.data.Orders);
      setOrderHistory(response?.data?.Orders);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchProducts();
  }, []);

  // Sort order history in descending order
  const sortedOrderHistory = orderHistory.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  //   console.log("orderhositry", orderHistory);
  return (
    <div>
      <Box
        mt={3}
        // sx={{ maxHeight: 250, overflowY: "auto" }}
      >
        <Scrollbar
          style={{ height: 250, width: "100%" }}
          trackYProps={{ style: { width: 4 } }}
        >
          {sortedOrderHistory.map((order, index) => (
            <Grid container spacing={1} key={order.id}>
              <Grid item sm={8}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    {index + 1}.
                  </Typography>
                  <Box sx={{ display: "flex", ml: 1 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {order.populatedUser[0]?.name}
                    </Typography>
                    <Box sx={{ display: "flex", ml: 2 }}>
                      <Typography variant="subtitle2">ID:</Typography>
                      <Typography variant="subtitle2">
                        {order?._id.slice(2, 8)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box ml={1}>
                  <Typography variant="caption">
                    {order?.status === "notaccepted"
                      ? `order denied at ${moment(order.updatedAt).format(
                          "LT"
                        )} `
                      : `
                    order picked on time ${moment(order.updatedAt).format("LT")}
                    `}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={4}>
                {/* <Typography variant="subtitle2">
                  {order.status === "orderpickedup"
                    ? "order_picked"
                    : "not_accepted"}
                </Typography> */}
                <Chip
                  size="small"
                  sx={{
                    backgroundColor:
                      order.status === "orderpickedup" ? "#E3FFE2" : "#FFCDD2",
                    color:
                      order.status === "orderpickedup" ? "#42FF00" : "#FF1744",
                    mt: 1,
                  }}
                  label={
                    order.status === "orderpickedup"
                      ? "order picked"
                      : "not accepted"
                  }
                />
              </Grid>
            </Grid>
          ))}
        </Scrollbar>
      </Box>
    </div>
  );
}

export default HistoryOrders;

import "./App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login2 from "./pages/Login/login2";
import CreateAccount from "./pages/CreateAccount/createAccount";
import UploadDocuments from "./pages/UploadDocument/uploadDocuments";
import Dashboard from "./pages/Dashboard/Dashboard";
import MyTranscation from "./pages/MyTransaction/MyTranscation";
import MyOrders from "./pages/MyOrder/MyOrders";
import InventoryManagement from "./pages/InventoryManagement/InventoryManagement";
import AddProduct from "./pages/AddProduct/AddProduct";
import MyProfile from "./pages/myProfile/MyProfile";
import MySettlements from "./pages/MySettlements/MySettlements";
import WithdrawAmount from "./components/MySettlements/WithdrawAmount";
import EditProduct from "./pages/UpdateProduct/EditProduct";
import ScrollToTopNew from "./components/ScrollToTOp/ScrollToTop";
import ProtectedRoutes from "./ProtectedRoute";
import UnVerified from "./pages/UnVerified";
import ProductCard from "./components/InventoryManagement/ProductCard";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer position={"top-center"} autoClose={1000} />
        <ScrollToTopNew />

        <Routes>
          <Route path="/" element={<Login2 />} />
          <Route path="/verification-pending" element={<UnVerified />} />

          <Route
            path="/createAccount"
            element={
              <ProtectedRoutes>
                <CreateAccount />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/upload"
            element={
              <ProtectedRoutes>
                <UploadDocuments />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/myTransaction"
            element={
              <ProtectedRoutes>
                <MyTranscation />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/myOrders"
            element={
              <ProtectedRoutes>
                <MyOrders />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoutes>
                <InventoryManagement />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/addProducts"
            element={
              <ProtectedRoutes>
                <AddProduct />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/upload"
            element={
              <ProtectedRoutes>
                <AddProduct />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/editProduct/:id"
            element={
              <ProtectedRoutes>
                <EditProduct />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/myProfile"
            element={
              <ProtectedRoutes>
                <MyProfile />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mySettlements"
            element={
              <ProtectedRoutes>
                <MySettlements />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes>
                <WithdrawAmount />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="/dashboard" element={} />
          <Route path="/myTransaction" element={<MyTranscation />} />
          <Route path="/myOrders" element={} />
          <Route path="/inventory" element={} />
          <Route path="/addProducts" element={<AddProduct />} />
          <Route path="/editProduct/:id" element={} />
          <Route path="/profile" element={} />
          <Route path="/mySettlements" element={<MySettlements />} />
          <Route path="/withdraw" element={} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

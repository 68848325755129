import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import { Box, Button, Typography } from "@mui/material";
import BankDetailsCard from "../../components/MySettlements/BankDetailsCard";
import MyActivityStepper from "../../components/MySettlements/MyActivityStepper";
import { useNavigate } from "react-router-dom";

function MySettlements() {
  const navigate = useNavigate();

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Box height={90} />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", mb: 4, color: "#7B029E" }}
          >
            My Settlements
          </Typography>

          {/* withdrawl */}
          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#7B029E" }}>
                Todays Earning
              </Typography>
              <Typography variant="subtitle2" textAlign={"center"} mt={1}>
                € 8,520
              </Typography>
            </Box>
            <Box ml={6}>
              <Typography variant="subtitle2" sx={{ color: "#7B029E" }}>
                Total Earning
              </Typography>
              <Typography variant="subtitle2" textAlign={"center"} mt={1}>
                € 28,520
              </Typography>
            </Box>
            <Box ml={8}>
              <Button
                variant="contained"
                size="small"
                onClick={() => navigate("/withdraw")}
                sx={{
                  background: "#7B029E",
                  textTransform: "capitalize",
                  color: "#FFFFFF",
                  borderRadius: 2,
                  "&:hover": {
                    background: "#7B029E",
                  },
                }}
              >
                Withdraw amount
              </Button>
            </Box>
          </Box>

          {/* bank details */}
          <Box mt={5}>
            <BankDetailsCard />
          </Box>
          {/* My activity */}
          <Box mt={5}>
            <MyActivityStepper />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default MySettlements;

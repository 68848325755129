import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Chip, Grid, Stack, Switch } from "@mui/material";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function ProductCard() {
  const [checked, setChecked] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const navigate = useNavigate();

  const handleChange = async (event, index) => {
    const newProducts = [...products];
    newProducts[index].status = event.target.checked
      ? "available"
      : "unavailable";
    setProducts(newProducts);
    // let formData = {
    //   status: productStatus,
    // };
    try {
      const tokenStr = localStorage.getItem("accessToken");
      const headers = {
        accessToken: tokenStr,
      };
      const response = await axios.put(
        API_PROD + `/product/updateProduct/${newProducts[index]._id}`,
        { status: newProducts[index].status },
        { headers }
      );
      console.log("Update status response:", response.data);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(API_PROD + "/product/getMyProduct", {
        headers,
      });
      setProducts(response?.data?.products);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProducts();
  }, []);

  const handleClick = async (id) => {
    console.info("You clicked the Chip.");
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    try {
      const response = await axios.get(
        API_PROD + `/product/getMyProductsByCategory/${id}`,
        { headers }
      );
      console.log("chip", response.data.products);
      setProducts(response?.data?.products);
      setSelectedCategory(id); // Set the selected category
      setIsCategorySelected(true);
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await axios.get(API_PROD + "/category/getCategory", {
        headers,
      });
      console.log("category", response.data);
      setCategories(response?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSelectAll = () => {
    setSelectedCategory(null);
    setShowMore(false);
  };

  return (
    <>
      {isLoading ? (
        <Box mb={2}>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Box>
      ) : (
        ""
      )}
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ml: 1,
            flexWrap: "wrap",
          }}
        >
          <Stack
            direction="row"
            spacing={0.5}
            position="start"
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Chip
              label="All"
              onClick={() => {
                fetchProducts();
                handleSelectAll();
              }}
              sx={{
                background: selectedCategory === null ? "#FFCC03" : "none",
                color: "#3D3D3D",
                border: "1px solid #DCDCDC",
                borderRadius: "10px",
                "&:hover": {
                  background: selectedCategory === null ? "#FFCC03" : "none",
                  cursor: "pointer",
                },
              }}
            />
            {categories
              .slice(0, showMore ? categories.length : 3)
              .map((i, index) => (
                <Chip
                  key={i._id}
                  label={i?.name}
                  onClick={() => handleClick(i._id)}
                  sx={{
                    background: i._id === selectedCategory ? "#FEDB70" : "none",
                    color: "#3D3D3D",
                    border: "1px solid #DCDCDC",
                    borderRadius: "10px",
                    "&:hover": {
                      background:
                        i._id === selectedCategory ? "#FEDB70" : "none",
                      cursor: "pointer",
                    },
                  }}
                />
              ))}
            {categories.length > 3 && (
              <Typography
                variant="caption"
                onClick={() => setShowMore(!showMore)}
                sx={{
                  background: "none",
                  color: "#7B029E",
                  fontWeight: "bold",
                  paddingTop: 0.4,
                  cursor: "pointer",
                  pt: 0.8,
                  pl: 1,
                }}
              >
                {showMore ? "-less" : `+${categories.length - 3} more`}
              </Typography>
            )}
          </Stack>
        </Box>

        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", mb: 2, mt: 3, ml: 1, color: "#333333" }}
        >
          View products
        </Typography>

        {isCategorySelected && products.length === 0 ? (
          <Typography variant="h6" sx={{ ml: 1, mt: 3, mb: 2, color: "red" }}>
            Sorry! No products found :(
          </Typography>
        ) : (
          <Grid container spacing={1} sx={{ mt: "-40px", p: 1 }}>
            {products.map((product, index) => (
              <Grid item xs={12} sm={3} sx={{ mt: 3 }} key={product._id}>
                <Box
                  sx={{
                    maxWidth: "230px",
                    color: "#3D3D3D",
                    // backgroundColor: "#faf7f7",
                    backgroundImage:
                      "linear-gradient(180deg, #faf7f7 0%, #ffffff 100%)",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "227px",
                        height: "125px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={product?.images[0] || "/noimage.png"}
                        alt=""
                        style={{
                          width: "100%",
                          height: "125px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {product?.name}
                        </Typography>
                        <Typography variant="subtitle2">
                          € {product?.price}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {product?.status}
                          {product.status === "available" ? (
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#5EFB44",
                                ml: 0.5,
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#FB4444",
                                ml: 0.5,
                              }}
                            />
                          )}
                        </Typography>

                        <Box sx={{ mr: "-9px", mt: "-4px" }}>
                          <Switch
                            checked={product.status === "available"} // Update this line
                            onChange={(event) => handleChange(event, index)}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                            // disabled={product.status === "unavailable"}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ cursor: "pointer" }}>
                        <Typography
                          sx={{
                            color: "#7B029E",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() =>
                            navigate(`/editProduct/${product._id}`)
                          }
                        >
                          Edit
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </>
    </>
  );
}

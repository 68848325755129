import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { useState } from "react";
import { toast } from "react-toastify";

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // localStorage.removeItem("accessToken");
    // toast.success("logged out successfully");
  };

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchProfileDetails = async () => {
    try {
      const response = await axios.get(API_PROD + "/vendor/getMyProfile", {
        headers,
      });
      console.log("profileDetails", response.data.vendor);
      setProfileDetails(response?.data?.vendor);
      //   setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      //   setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProfileDetails();
  }, []);
  return (
    <React.Fragment>
      <>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 36, height: 36 }}>
                {profileDetails?.images.length > 0 ? (
                  <img
                    src={profileDetails?.user.images[0]}
                    alt=""
                    style={{ width: "100%" }}
                  />
                ) : (
                  <img src="/shop-log.png" alt="" style={{ width: "100%" }} />
                )}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose} sx={{ fontWeight: "bold" }}>
            {profileDetails?.shopname}
          </MenuItem>

          {/* <Divider /> */}

          {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem> */}
        </Menu>
      </>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { toast } from "react-toastify";
import { useAppStore } from "../../appStore";

function UploadDocuments() {
  const navigate = useNavigate();
  // const [selectedFiles, setSelectedFiles] = useState(null);
  const [open, setOpen] = useState(false);

  const setSelectedFiles = useAppStore((state) => state.setSelectedFiles);
  const selectedFiles = useAppStore((state) => state.selectedFiles);

  const setPdfFileUrl = useAppStore((state) => state.setPdfFileUrl);
  const pdfFileUrl = useAppStore((state) => state.pdfFileUrl);

  const setUploadPdfUrl = useAppStore((state) => state.setUploadPdfUrl);
  const uploadPdfUrl = useAppStore((state) => state.uploadPdfUrl);

  const [language, setLanguage] = useState("english");

  const handleCancel = () => {
    // navigate("/createAccount");
    setSelectedFiles("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter(
        (file) => file.type === "application/pdf"
      );
      if (validFiles.length > 0) {
        setSelectedFiles(validFiles);
        toast.success("Files selected successfully.");
      } else {
        setSelectedFiles([]);
        toast.error("Please select valid .pdf files.");
      }
    } else {
      setSelectedFiles([]);
      toast.error("Please select files to upload.");
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      toast.error("Please select files to upload.");
      return;
    }

    let tokenStr = localStorage.getItem("accessToken");

    try {
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("image", file, file.name);

        const response = await axios.post(
          API_PROD + "/uploadImage/",
          formData,
          {
            headers: { accessToken: tokenStr },
          }
        );

        if (response.data.status === "success") {
          const { fileUrl } = response.data;
          setUploadPdfUrl(fileUrl);

          const pdfUrl = URL.createObjectURL(file);
          setPdfFileUrl(pdfUrl);
          console.log("slectedFileUrl", pdfUrl);

          console.log("slectedFile", selectedFiles);
          toast.success("File uploaded successfully.");
          navigate("/createAccount");
        } else {
          toast.error("Failed to upload file.");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while uploading the files.");
    }
  };

  // useEffect(() => {
  //   if (selectedFiles) {
  //     setPdfFileUrl(selectedFiles);
  //   }
  // }, [selectedFiles]);

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  return (
    <>
      <Grid container sx={{ display: "flex", padding: "20px 20px" }}>
        {/* ... */}
        <Grid
          item
          md={6}
          sx={{
            bgcolor: "rgba(123, 2, 158, 0.15)",
            borderRadius: "30px",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Grid>
            <img src="/BOTOLogo.png" width={120} alt="" />
          </Grid>
          <Grid mt={2}>
            <img src="/image3.png" width={310} alt="" />
          </Grid>
        </Grid>
        <Grid item md={6}>
          {/* ... */}
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              justifyContent: { xs: "flex-end", sm: "flex-end" },
              marginRight: { xs: "10px", sm: "180px" },
              mt: { xs: 0, sm: "10px" },
            }}
          >
            <Typography
              onClick={() => handleLanguageChange("english")}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                marginRight: "10px",
                marginTop: "2px",
                position: "relative",
                fontWeight: language === "english" ? "bold" : "normal",
              }}
            >
              English
              {language === "english" && (
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: "-2px",
                    width: "100%",
                    height: "2px",
                    background: "#7B029E",
                  }}
                />
              )}
            </Typography>
            <Typography
              onClick={() => handleLanguageChange("deutsch")}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                marginTop: "2px",
                position: "relative",
                fontWeight: language === "deutsch" ? "bold" : "normal",
              }}
              ml={2}
            >
              Deutsch
              {language === "deutsch" && (
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: "-2px",
                    width: "100%",
                    height: "2px",
                    background: "#7B029E",
                  }}
                />
              )}
            </Typography>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{ padding: { xs: 0, sm: "40px 140px 0px 140px" } }}
          >
            {/* ... */}
            <Grid item sm={12} xs={12} mb={3}>
              <Typography
                variant="h5"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {language == "english"
                  ? "Upload Documents"
                  : "Dokumente hochladen"}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box mt={1} sx={{ display: "flex" }}>
                <Box>
                  <input
                    type="file"
                    id="select-file"
                    name="file"
                    accept=".pdf"
                    style={{ display: "none", textAlign: "center" }}
                    onChange={handleFileChange}
                    multiple
                  />

                  <label htmlFor="select-file">
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      component="span"
                      sx={{
                        marginLeft: language === "english" ? 5 : 0,
                        textTransform: "capitalize",
                      }}
                    >
                      {language === "english"
                        ? "Upload documents in .pdf"
                        : "Laden Sie Dokumente im PDF-Format hoch"}
                    </Button>
                  </label>
                </Box>
              </Box>
              {selectedFiles && selectedFiles.length > 0 && (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{ ml: 4, fontSize: "10px" }}
                  >
                    {selectedFiles.map((file) => (
                      <div key={file.name}>{file.name}</div>
                    ))}
                  </Typography>
                  {/* <div>
                    <a
                      href={pdfFileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View PDF
                    </a>
                  </div> */}
                </>
              )}
            </Grid>

            <Box sx={{ width: "100%", mx: 2, display: "flex" }}>
              <Button
                size="small"
                onClick={handleCancel}
                variant="contained"
                className="loginHover"
                fullWidth
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "red",
                  mt: 4,
                  padding: 1,
                  fontWeight: "bold",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "red",
                  },
                  textTransform: "capitalize",
                }}
              >
                {language === "english" ? "Cancel" : "Stornieren"}
              </Button>
              <Button
                size="small"
                onClick={handleFileUpload}
                variant="contained"
                className="loginHover"
                fullWidth
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FFC100",
                  mt: 4,
                  padding: 1,
                  fontWeight: "bold",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#FFC100",
                  },
                  ml: 1,
                  textTransform: "capitalize",
                }}
              >
                {language === "english" ? "Save" : "Speichern"}
              </Button>
            </Box>
            {/* ... */}
            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Verification onCloseEvent={handleClose} />
            </Modal> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default UploadDocuments;

import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Typography } from "@mui/material";
import PhoneField from "../../components/phoneField/phoneField";
import OTPInput from "react-otp-input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { useAppStore } from "../../appStore";
import { toast } from "react-toastify";

function Login2() {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const { setPhoneNumber, setOtpResponse } = useAppStore();
  const phoneNumber = useAppStore((state) => state.phoneNumber);
  const otpResponse = useAppStore((state) => state.otpResponse);
  const [language, setLanguage] = useState("english");

  // Function to encrypt the phone number
  const encryptPhoneNumber = (phoneNumber) => {
    // Replace the middle digits with 'x' characters
    const encryptedDigits = phoneNumber.slice(4, -2).replace(/\d/g, "x");

    // Reconstruct the encrypted phone number
    const encryptedPhoneNumber =
      phoneNumber.slice(0, 5) + encryptedDigits + phoneNumber.slice(-2);

    return encryptedPhoneNumber;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    // Reset the OTP field and timer
    // setOtp("");
    setTimer(30);
    const role = "vendor";
    const apikey = `${phoneNumber}:${otp}:${role}`;
    const encodedApiKey = btoa(apikey);

    const headers = {
      apikey: encodedApiKey,
    };
    axios
      .post(API_PROD + "/auth/verifyOtp", {}, { headers })
      .then((response) => {
        // Handle the API response
        // console.log("responselogin", response.data);
        const data = response.data;
        if (data.user.name && data.user.email && data.user.role === "vendor") {
          localStorage.setItem("accessToken", data.accessToken.value);
          toast.success("Logged in successfully");
          navigate("/dashboard");
          setPhoneNumber("");
          setOtpResponse("");
        } else {
          localStorage.setItem("accessToken", data.accessToken.value);
          navigate("/createAccount");
          setPhoneNumber("");
          setOtpResponse("");
        }
      })
      .catch((error) => {
        // Handle errors
        toast.error("something went wrong !!");
        console.error(error);
      });
  };

  useEffect(() => {
    let intervalId;

    if (otpResponse === true && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [otpResponse, timer]);

  // resend otp
  const handleResendOtp = (event) => {
    event.preventDefault();

    const role = "vendor";
    const apikey = `${phoneNumber}:${role}`;
    const encodedApiKey = btoa(apikey);

    const headers = {
      apikey: encodedApiKey,
    };
    // console.log("headers", headers);
    axios
      .post(API_PROD + "/auth/login", {}, { headers })
      .then((response) => {
        // Handle the API response
        // console.log(response.data);
        const encryptedPhoneNumber = encryptPhoneNumber(phoneNumber);
        toast.success(`otp sent to phonenumber ${encryptedPhoneNumber} `);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  return (
    <>
      <Grid container sx={{ display: "flex", padding: "20px 20px" }}>
        <Grid
          item
          md={6}
          sx={{
            bgcolor: "rgba(123, 2, 158, 0.15)",
            borderRadius: "30px",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Grid>
            <img src="/BOTOLogo.png" width={120} alt="" />
          </Grid>
          <Grid mt={2}>
            <img src="/image1.png" width={380} alt="" />
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              justifyContent: { xs: "flex-end", sm: "flex-end" },
              marginRight: { xs: "10px", sm: "180px" },
              mt: { xs: 0, sm: "10px" },
            }}
          >
            <Typography
              onClick={() => handleLanguageChange("english")}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                marginRight: "10px",
                marginTop: "2px",
                position: "relative",
                fontWeight: language === "english" ? "bold" : "normal",
              }}
            >
              English
              {language === "english" && (
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: "-2px",
                    width: "100%",
                    height: "2px",
                    background: "#7B029E",
                  }}
                />
              )}
            </Typography>
            <Typography
              onClick={() => handleLanguageChange("deutsch")}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                marginTop: "2px",
                position: "relative",
                fontWeight: language === "deutsch" ? "bold" : "normal",
              }}
              ml={2}
            >
              Deutsch
              {language === "deutsch" && (
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: "-2px",
                    width: "100%",
                    height: "2px",
                    background: "#7B029E",
                  }}
                />
              )}
            </Typography>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{ padding: { xs: 0, sm: "40px 140px 0px 140px" } }}
          >
            <Grid item sm={12} xs={12} mb={3}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                {language === "english"
                  ? "Welcome to BOTO"
                  : "Willkommen bei BOTO"}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <PhoneField />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>
                {language === "english"
                  ? "Enter one-time password"
                  : "Einmaliges Passwort eingeben"}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <OTPInput
                inputStyle={{
                  width: "3.4em",
                  height: "3.4em",
                  border: "1px solid #A0A0A0",
                  borderRadius: "6px",
                  marginRight: "3px",
                }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              {otpResponse === true && (
                <Box sx={{ textAlign: "end", mr: 1 }}>
                  <Typography variant="caption" sx={{ color: "#000000" }}>
                    {timer > 0
                      ? `OTP expires in ${timer} seconds`
                      : "OTP expired"}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              sx={{
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Grid
                item
                sm={8}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="caption" sx={{ color: "#000000" }}>
                  {language === "english"
                    ? "Enter the four-digit OTP that we have sent via the phone number"
                    : "Geben Sie den vierstelligen OTP ein, den wir über die Telefonnummer gesendet haben"}
                </Typography>
                <Typography
                  variant="caption"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  {encryptPhoneNumber(phoneNumber)}
                </Typography>
              </Grid>
              <Grid item sm={4} ml={1.5}>
                <Typography
                  variant="subtitle2"
                  onClick={handleResendOtp}
                  sx={{
                    color:
                      otpResponse === true && timer > 0 ? "#A0A0A0" : "#FFC100",
                    cursor:
                      otpResponse === true && timer > 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {language === "english"
                    ? otpResponse === true && timer > 0
                      ? `Resend OTP (${timer})`
                      : "Resend OTP"
                    : otpResponse === true && timer > 0
                    ? `OTP erneut senden (${timer})`
                    : "OTP erneut senden"}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", ml: 1 }}>
              <Button
                variant="contained"
                className="loginHover"
                onClick={handleLogin}
                fullWidth
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FFC100",
                  mt: 3,
                  padding: 2,
                  fontWeight: "bold",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#FFC100",
                  },
                }}
              >
                {language === "english" ? "LOGIN" : "ANMELDUNG"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Login2;

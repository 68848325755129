import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/SideBar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PlaceIcon from "@mui/icons-material/Place";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useAppStore } from "../../appStore";

function MyProfile() {
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [shopName, setShopName] = useState("");
  const [documents, setDoucments] = useState(null);
  const [uploadPdfUrl, setUploadPdfUrl] = useState(null);
  const [pdfFile, setPdfFileUrl] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { pdfFileUrl } = useAppStore();
  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchProfileDetails = async () => {
    try {
      const response = await axios.get(API_PROD + "/vendor/getMyProfile", {
        headers,
      });
      // console.log("profileDetails", response.data.vendor);
      setProfileDetails(response?.data?.vendor);
      //   setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      //   setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProfileDetails();
  }, []);

  useEffect(() => {
    if (profileDetails) {
      setName(profileDetails.user.name);
      setEmail(profileDetails.user.email);
      setLocation(profileDetails.address);
      setShopName(profileDetails.shopname);
      setDoucments(profileDetails.documents || []);
      setUploadPdfUrl(profileDetails?.documents[0] || null);
      setPdfFileUrl(profileDetails?.documents[0] || null);
      setImageUrl(profileDetails.images[0]);
      setUploadUrl(profileDetails.images[0]);
      setIsLoading(false);
    }
  }, [profileDetails]);

  //image upload
  const handleImageChange = async (e) => {
    setSelectedImage(e.target.files[0]);
    console.log("selctedImge", e.target.files[0]);
    let tokenStr = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const response = await axios.post(API_PROD + `/uploadImage/`, formData, {
        headers: { accesstoken: tokenStr },
      });
      if (response.data.status === "success") {
        // Handle success case
        let data = response.data;
        setUploadUrl(data.fileUrl);
        toast.success("image selected.");
      }
    } catch (error) {
      // Handle error case
      toast.error("An error occurred while uploading your file.");
    }
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  //edit profile
  const editProfile = async () => {
    await handleFileUpload();
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    let formData = {
      name,
      email,
      location,
      shopname: shopName,
      documents: [uploadPdfUrl],
      images: [uploadUrl],
    };
    try {
      const response = await axios.put(
        API_PROD + `/vendor/updateMyProfile`,
        formData,
        { headers }
      );
      if (response.data.status === "success") {
        // Handle success case
        let data = response.data;
        toast.success("Profile updated successfully.");
        // navigate("/dashboard");
      }
    } catch (error) {
      // Handle error case
      toast.error("An error occurred while submitting your file.");
    }
  };

  const openDocument = (url) => {
    window.open(url, "_blank");
  };

  //uplaod document
  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter(
        (file) => file.type === "application/pdf"
      );
      if (validFiles.length > 0) {
        setSelectedFiles(validFiles);
        toast.success("Files selected successfully.");
      } else {
        setSelectedFiles([]);
        toast.error("Please select valid .pdf files.");
      }
    } else {
      setSelectedFiles([]);
      toast.error("Please select files to upload.");
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      // toast.error("Please select files to upload.");
      return;
    }

    let tokenStr = localStorage.getItem("accessToken");

    try {
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("image", file, file.name);

        const response = await axios.post(
          API_PROD + "/uploadImage/",
          formData,
          {
            headers: { accessToken: tokenStr },
          }
        );

        if (response.data.status === "success") {
          const { fileUrl } = response.data;
          setUploadPdfUrl(fileUrl);
          console.log("slectedFileUrl", fileUrl);
          console.log("slectedFile", uploadPdfUrl);

          const pdfUrl = URL.createObjectURL(file);
          setPdfFileUrl(pdfUrl);
          console.log("slectedFile", selectedFiles);
          toast.success("File uploaded successfully.");
        } else {
          toast.error("Failed to upload file.");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while uploading the files.");
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Box height={90} />
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#7B029E" }}
                >
                  Business profile
                </Typography>
                <Box mt={1}>
                  <DeleteOutlineIcon />
                </Box>
              </Box>

              <Box mt={4}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter your name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={name} // Populate with profileDetails.name
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>
                <Box mt={3}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter your email ID"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={email} // Populate with profileDetails.email
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box mt={3}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Location"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaceIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={location} // Populate with profileDetails.location
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Box>
                <Box mt={3}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Shop Name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <StorefrontIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={shopName} // Populate with profileDetails.shopName
                    onChange={(e) => setShopName(e.target.value)}
                  />
                </Box>
                <Box mt={3}>
                  <input
                    type="file"
                    id="select-file"
                    name="file"
                    accept=".pdf"
                    style={{ display: "none", textAlign: "center" }}
                    onChange={handleFileChange}
                    multiple
                  />

                  <label htmlFor="select-file">
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      Upload documents in .pdf
                    </Button>
                  </label>
                </Box>
                {selectedFiles && (
                  <Typography variant="subtitle2" sx={{ fontSize: "10px" }}>
                    {selectedFiles.map((file) => (
                      <div key={file.name}>{file.name}</div>
                    ))}
                  </Typography>
                )}

                <Box mt={3}>
                  <Typography variant="subtitle1" sx={{ color: "#72777A" }}>
                    Shop Documents
                  </Typography>
                  {profileDetails && profileDetails.documents && (
                    <div mt={1} className="editProfileLink">
                      {profileDetails.documents.map((document, index) => (
                        <Tooltip title="Click to download and view" key={index}>
                          <a onClick={() => openDocument(document)}>
                            {/* {profileDetails?.createdAt.slice(0, 10)}-Doc-
                            {index + 1}.pdf */}
                            {document}
                          </a>
                        </Tooltip>
                      ))}
                    </div>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  mt: 10,
                  padding: { xs: "0px", sm: "0px 110px" },
                }}
              >
                <Button
                  onClick={editProfile}
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 6,
                    background: "#7B029E",
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "#7B029E",
                    },
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Grid>
            <Grid item sm={4}>
              <Box sx={{ padding: "40px", ml: 8, cursor: "pointer" }}>
                <Box
                  sx={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={
                        imageUrl ? profileDetails?.images[0] : "/shop-log.png"
                      }
                      alt="no image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>

                <Box>
                  <input
                    type="file"
                    id="select-image"
                    name="image"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="select-image">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#72777A",
                        display: "flex",
                        alignItems: "center",
                        ml: 2.5,
                        cursor: "pointer",
                      }}
                    >
                      Shop pic
                      <BorderColorIcon
                        sx={{ width: "14px", ml: 1, color: "#72777A" }}
                      />
                    </Typography>
                  </label>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* New JSX code for the image */}
      <div
        style={{
          position: "fixed",
          bottom: -6,
          left: 670,
          zIndex: 9999,
        }}
      >
        <img width={600} src="/profileOutlay.png" alt="Bottom Right Image" />
      </div>
    </div>
  );
}

export default MyProfile;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { useAppStore } from "../../appStore";

function CreateAccount({ onCloseEvent }) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  // const [location, setLocation] = useState({
  //   type: "Point",
  //   coordinates: [],
  // });
  const [shopName, setShopName] = useState("");
  const {
    selectedFiles,
    setSelectedFiles,
    pdfFileUrl,
    setPdfFileUrl,
    uploadUrl,
  } = useAppStore();
  const [documents, setDoucments] = useState([uploadUrl]);

  console.log("uploadUrlAcc", documents);
  // const [isVerified, setIsVerified] = useState();
  const [language, setLanguage] = useState("english");

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };
  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleNext = async () => {
    try {
      if (!name || !email || !shopName) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const response = await axios.post(
        API_PROD + "/vendor/createAccount",
        {
          name: name,
          email: email,
          location: location,
          shopname: shopName,
          documents: documents,
        },
        { headers }
      );

      toast.success("Account created successfully !");
      navigate("/dashboard");
      // console.log("newAccount", response.data);
      // Nullify the saved form data
      // setLocation({ type: "Point", coordinates: [] });
      localStorage.removeItem("createAccountFormData");

      setName("");
      setEmail("");
      setLocation("");
      setShopName("");
    } catch (error) {
      toast.error("Something went wrong !!");
    }
  };

  useEffect(() => {
    const savedFormData = JSON.parse(
      localStorage.getItem("createAccountFormData")
    );

    if (savedFormData) {
      setName(savedFormData.name || "");
      setEmail(savedFormData.email || "");
      // setLocation(savedFormData.location || { type: "Point", coordinates: [] });
      setLocation(savedFormData.location || "");
      setShopName(savedFormData.shopName || "");
      setDoucments(savedFormData.documents || [uploadUrl]);
    }
  }, []);

  useEffect(() => {
    const formData = {
      name,
      email,
      location,
      shopName,
      documents,
    };
    localStorage.setItem("createAccountFormData", JSON.stringify(formData));
  }, [name, email, location, shopName, documents]);

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  return (
    <>
      <Grid container sx={{ display: "flex", padding: "20px 20px" }}>
        <Grid
          item
          md={6}
          sx={{
            bgcolor: "rgba(123, 2, 158, 0.15)",
            borderRadius: "30px",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Box>
            <img src="/BOTOLogo.png" width={120} alt="" />
          </Box>
          <Box mt={2}>
            <img src="/image3.png" width={310} alt="" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              justifyContent: { xs: "flex-end", sm: "flex-end" },
              marginRight: { xs: "10px", sm: "180px" },
              mt: { xs: 0, sm: "10px" },
            }}
          >
            <Typography
              onClick={() => handleLanguageChange("english")}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                marginRight: "10px",
                marginTop: "2px",
                position: "relative",
                fontWeight: language === "english" ? "bold" : "normal",
              }}
            >
              English
              {language === "english" && (
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: "-2px",
                    width: "100%",
                    height: "2px",
                    background: "#7B029E",
                  }}
                />
              )}
            </Typography>
            <Typography
              onClick={() => handleLanguageChange("deutsch")}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                marginTop: "2px",
                position: "relative",
                fontWeight: language === "deutsch" ? "bold" : "normal",
              }}
              ml={2}
            >
              Deutsch
              {language === "deutsch" && (
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: "-2px",
                    width: "100%",
                    height: "2px",
                    background: "#7B029E",
                  }}
                />
              )}
            </Typography>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{ padding: { xs: 0, sm: "40px 140px 0px 140px" } }}
          >
            <Grid item sm={12} xs={12} mb={2}>
              <Typography
                variant="h5"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {language === "english"
                  ? "Create your account"
                  : "Erstelle deinen Account"}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                size="small"
                fullWidth
                placeholder={
                  language === "english"
                    ? "Enter your name"
                    : "Gib deinen Namen ein"
                }
                required
                value={name}
                onChange={(e) => handleInputChange(e, setName)}
              />
              <Typography variant="caption" sx={{ color: "red" }}>
                {language === "english" ? " * required field" : "* Pflichtfeld"}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                size="small"
                fullWidth
                type="email"
                required
                value={email}
                placeholder={
                  language === "english"
                    ? "Enter your email ID"
                    : "Geben Sie Ihre E-Mail-ID ein"
                }
                onChange={(e) => handleInputChange(e, setEmail)}
              />
              <Typography variant="caption" sx={{ color: "red" }}>
                {language === "english" ? " * required field" : "* Pflichtfeld"}
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                size="small"
                value={location} // Convert the coordinates array to a comma-separated string
                fullWidth
                placeholder={
                  language === "english" ? "Enter Location" : "Ort eingeben"
                }
                onChange={(e) => handleInputChange(e, setLocation)}
              />
              <Typography variant="caption" sx={{ color: "red" }}>
                {language === "english" ? " * required field" : "* Pflichtfeld"}
              </Typography>
            </Grid>

            {/* <Grid item sm={12} xs={12}>
              <TextField
                size="small"
                value={location.coordinates.join(",")} // Convert the coordinates array to a comma-separated string
                fullWidth
                placeholder="Location"
                onChange={(e) =>
                  handleInputChange(e, (value) =>
                    setLocation({
                      type: "Point",
                      coordinates: value.split(","),
                    })
                  )
                }
              />
              <Typography variant="caption" sx={{ color: "red" }}>
                                {language === "english" ? " * required field" : "* Pflichtfeld"}

              </Typography>
            </Grid> */}
            <Grid item sm={12} xs={12}>
              <TextField
                size="small"
                fullWidth
                required
                value={shopName}
                placeholder={
                  language === "english"
                    ? "Enter Shop name"
                    : "Shopnamen eingeben"
                }
                onChange={(e) => handleInputChange(e, setShopName)}
              />
              <Typography variant="caption" sx={{ color: "red" }}>
                {language === "english" ? " * required field" : "* Pflichtfeld"}
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12}>
              <Button
                onClick={() => navigate("/upload")}
                fullWidth
                variant="outlined"
                placeholder="Registration Documents"
              >
                {language === "english"
                  ? "Registration Documents"
                  : "Registrierungsunterlagen"}
              </Button>
            </Grid>

            <Box sx={{ width: "100%", ml: 1 }}>
              <Button
                variant="contained"
                className="loginHover"
                onClick={handleNext}
                fullWidth
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FFC100",
                  mt: 4,
                  padding: 2,
                  fontWeight: "bold",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#FFC100",
                  },
                }}
              >
                {language === "english" ? "Next" : "Nächste"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateAccount;

import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Switch,
  Typography,
  MenuItem,
  Select,
  Dialog,
  IconButton,
} from "@mui/material";

import { API_PROD } from "../apiUtils";
import axios from "axios";

import { useAppStore } from "../appStore";


const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #7B029E",
  borderRadius: 1.5,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  p: 2,
  textAlign: "center",
};

function UnVerified() {
  const [checked, setChecked] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const setOngoingOrders = useAppStore((state) => state.setOngoingOrders);
  const ongoingOrders = useAppStore((state) => state.ongoingOrders);
  const [isLoading, setIsLoading] = useState(true);
  const [statusMap, setStatusMap] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isVerified, setIsVerified] = useState(Boolean);

  const setVendorId = useAppStore((state) => state.setVendorId);
  const vendorId = useAppStore((state) => state.vendorId);

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchProfileDetails = async () => {
    try {
      const response = await axios.get(API_PROD + "/vendor/getMyProfile", {
        headers,
      });
      setVendorId(response.data?.vendor?._id);
      console.log("vendorID", response.data?.vendor?._id);

      setOpenDialog(!response.data?.vendor?.isVerified);
      setIsDialogOpen(!response.data?.vendor?.isVerified);
      console.log("isverifed", response.data?.vendor?.isVerified);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };
  const handleDialogClose = () => {
    // if (isVerified === false) {
    //   // Dialog should not close when isVerified is false
    //   return;
    // }
    setOpenDialog(false);
  };

  React.useEffect(() => {
    fetchProfileDetails();
    // if(isVerified === false){
    //   setOpenDialog(true);
    // }
  }, [fetchProfileDetails()]);

  return (
    <div>
      {isVerified === false ? (
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <div style={style}>
            {" "}
            <Box sx={{ width: { xs: 250, sm: 360 } }}>
              <Typography
                variant="h6"
                id="child-modal-title"
                sx={{ fontWeight: "bold" }}
              >
                Verification pending{" "}
              </Typography>

              <Box sx={{ padding: "10px" }}>
                <img src="logo-vector.png" alt="no image found" />
              </Box>

              <Typography
                variant="subtitle2"
                id="child-modal-description"
                mb={1}
              >
                Please wait for 24 hrs and our team will send you an email after
                your status is updated
              </Typography>
            </Box>
          </div>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}

export default UnVerified;

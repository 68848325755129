import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAppStore } from "../../appStore";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #7B029E",
  borderRadius: 1.5,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  pt: 2,
  px: 4,
  pb: 3,
};
function Parameters({ onCloseEvent }) {
  const setParameter = useAppStore((state) => state.setParameter);
  const parameter = useAppStore((state) => state.parameter);
  const [fat, setFat] = useState("");
  const [fatValue, setFatValue] = useState("");
  const data = JSON.stringify(parameter);
  // console.log("data", data);
  const handleButtonClick = () => {
    setParameter(fat, fatValue);
    onCloseEvent();
  };
  return (
    <div>
      <Box sx={{ ...style, width: { xs: 250, sm: 340 } }}>
        <Typography sx={{ color: "#7B029E" }}>Type Parameter</Typography>
        <Box mt={2}>
          <TextField
            value={fat}
            onChange={(e) => setFat(e.target.value)}
            fullWidth
            size="small"
            placeholder="type parameter"
          />
          <TextField
            fullWidth
            value={fatValue}
            onChange={(e) => setFatValue(e.target.value)}
            size="small"
            placeholder="type amount"
            sx={{ mt: 1 }}
          />
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            sx={{
              float: "right",
              backgroundColor: "#7B029E",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#7B029E",
              },
            }}
            onClick={handleButtonClick}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default Parameters;

import React from 'react'
import MyOrdersTable from '../../components/myOrdersTable/MyOrdersTable'
import Sidebar from '../../components/sidebar/SideBar'
import { Box, Typography } from '@mui/material'
import OrderStatusTable from '../../components/myOrdersTable/OrderStatusTable'

function MyOrders() {
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ p: 1 }}>
          <Box height={90} />
          <Typography variant='h6' sx={{ fontWeight: "bold", mb: 2, ml: 1, color: "#7B029E" }}>My Orders</Typography>
          <MyOrdersTable />
          <Box component="main" sx={{ mt: 2 }}>
            <Typography variant='h6' sx={{ fontWeight: "bold", mb: 2, ml: 1, color: "#7B029E" }}>Order Status</Typography>
            <OrderStatusTable />

          </Box>
        </Box>
       

      </Box>
    </div>
  )
}

export default MyOrders

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { API_PROD } from "./apiUtils";

const ProtectedRoutes = ({ children }) => {
  const [isVerified, setIsVerified] = useState(Boolean);
  const [isLoading, setIsLoading] = useState(true);

  const result = localStorage.getItem("accessToken");
  const headers = {
    accessToken: result,
  };

  const fetchProfileDetails = async () => {
    try {
      const response = await axios.get(API_PROD + "/vendor/getMyProfile", {
        headers,
      });
      const updatedIsVerified = response.data?.vendor?.isVerified;
      setIsVerified(updatedIsVerified);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    fetchProfileDetails();
  }, []); // Empty dependency array

  console.log("isVerified:", isVerified);

  if (!result) {
    console.log("Redirecting to /");
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return null; // or any loading indicator component
  }

  if (!isVerified) {
    return <Navigate to="/verification-pending" />;
  }

  console.log("Allowing access to protected route");
  return children;
};

export default ProtectedRoutes;

import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/SideBar";
import {
  Box,
  Grid,
  Switch,
  Typography,
  MenuItem,
  Select,
  Dialog,
  IconButton,
} from "@mui/material";
import AcceptOrdersTable from "../../components/Dashboard/AcceptOrdersTable";
import HistoryOrders from "../../components/Dashboard/HistoryOrders";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { useAppStore } from "../../appStore";
import { Close } from "@mui/icons-material";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #7B029E",
  borderRadius: 1.5,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  p: 2,
  textAlign: "center",
};

const orderStatusOptions = [
  { value: "preparing", label: "Preparing" },
  { value: "ready", label: "Ready to pick up" },
  { value: "orderpickedup", label: "Order picked up" },
];

function Dashboard({ onCloseEvent }) {
  const [checked, setChecked] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const setOngoingOrders = useAppStore((state) => state.setOngoingOrders);
  const ongoingOrders = useAppStore((state) => state.ongoingOrders);
  const [isLoading, setIsLoading] = useState(true);
  const [statusMap, setStatusMap] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isVerified, setIsVerified] = useState(Boolean);

  const setVendorId = useAppStore((state) => state.setVendorId);
  const vendorId = useAppStore((state) => state.vendorId);

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchProfileDetails = async () => {
    try {
      const response = await axios.get(API_PROD + "/vendor/getMyProfile", {
        headers,
      });
      setVendorId(response.data?.vendor?._id);
      console.log("vendorID", response.data?.vendor?._id);

      setOpenDialog(!response.data?.vendor?.isVerified);
      setIsDialogOpen(!response.data?.vendor?.isVerified);
      console.log("isverifed", response.data?.vendor?.isVerified);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   let timeoutId;

  //   if (isVerified === false) {
  //     timeoutId = setTimeout(() => {
  //       setOpenDialog(true);
  //     }, 3000); // Adjust the delay as needed
  //   } else {
  //     setOpenDialog(false);
  //   }

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [isVerified]);

  React.useEffect(() => {
    fetchProfileDetails();
    // if(isVerified === false){
    //   setOpenDialog(true);
    // }
  }, [fetchProfileDetails()]);
  useEffect(() => {
    fetchOngoingOrders();
  }, []);

  // useEffect(() => {}, [isVerified, setOpenDialog]);

  const updateShopStatus = async (status) => {
    try {
      const tokenStr = localStorage.getItem("accessToken");
      const headers = {
        accessToken: tokenStr,
      };
      const response = await axios.put(
        API_PROD + "/vendor/updateMyProfile",
        { status },
        { headers }
      );
      toast.success("Shop " + (status ? "activated" : "inactive") + ".");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSwitchChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    updateShopStatus(newChecked);
  };

  const handleStatusChange = async (event, id) => {
    const newStatus = event.target.value;
    setStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [id]: newStatus,
    }));

    try {
      const tokenStr = localStorage.getItem("accessToken");
      const headers = {
        accessToken: tokenStr,
      };
      const response = await axios.put(
        API_PROD + `/order/updateOrder/${id}`,
        { status: newStatus },
        { headers }
      );
      toast.success("Order status updated successfully.");
      // Update the ongoingOrders state if the status is not "readytopickup"
      if (newStatus !== "readytopickup") {
        setOngoingOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === id ? { ...order, status: newStatus } : order
          )
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const tokenStr = localStorage.getItem("accessToken");
  // const headers = {
  //   accessToken: tokenStr,
  // };

  const fetchOngoingOrders = async () => {
    try {
      const response = await axios.get(
        API_PROD + "/order/vendorOngoingOrders",
        {
          headers,
        }
      );
      const statusMap = response.data.ongoingOrders.reduce(
        (map, order) => ({
          ...map,
          [order._id]: "preparing",
        }),
        {}
      );
      setStatusMap(statusMap);
      setOngoingOrders(response.data.ongoingOrders);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Box height={80} />
          <Box>
            {/* go boto */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box></Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: "#333333", fontSize: "20px" }}>
                  Go BOTO
                </Typography>
                <Switch
                  checked={checked}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              </Box>
            </Box>

            {/* order status */}
            <Box mt={3}>
              <Typography sx={{ color: "#7B029E", fontWeight: 500 }}>
                Ongoing Order status
              </Typography>
              <Box mt={3}>
                <Grid container spacing={1}>
                  {ongoingOrders.map((order, key) => (
                    <Grid item sm={4} key={key}>
                      <Box
                        sx={{
                          background: "#FBFBFB",
                          width: "300px",
                          borderRadius: 2,
                        }}
                      >
                        <Grid container>
                          <Grid item sm={5} sx={{ display: "flex" }}>
                            <Typography sx={{ fontSize: "14px" }}>
                              {key + 1}.
                            </Typography>
                            <Box>
                              <Typography ml={0.1} sx={{ fontSize: "14px" }}>
                                {order?.populatedUser[0]?.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="secondary"
                                sx={{ fontSize: "9px" }}
                              >
                                Order pickup time 11:00 AM
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box ml={1}>
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Typography sx={{ fontSize: "14px" }}>
                                  ID:
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color: "#7B029E",
                                  }}
                                  ml={0.5}
                                >
                                  {order?._id.slice(2, 8)}
                                </Typography>
                              </Box>
                              <Select
                                size="small"
                                value={statusMap[order?._id] || "preparing"}
                                onChange={(e) =>
                                  handleStatusChange(e, order?._id)
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    border: "none", // Remove the border
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none", // Remove the outline
                                  },
                                  "&:hover .MuiOutlinedInput-root": {
                                    border: "none", // Remove the border on hover
                                  },
                                  "&:focus .MuiOutlinedInput-root": {
                                    border: "none", // Remove the border on focus
                                  },
                                  color: "red",
                                  fontSize: "12px",
                                  ml: "-12px",
                                }}
                              >
                                {orderStatusOptions.map((option) => (
                                  <MenuItem
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Grid>
                          <Grid item sm={1}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                ml: 2,
                                mt: 0.5,
                              }}
                            >
                              <img src="ready-logo.svg" alt="" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            {/* Accept orders */}
            <Box mt={4}>
              <Grid container spacing={2}>
                <Grid item sm={8}>
                  <Typography sx={{ color: "#7B029E", fontWeight: 500 }}>
                    Accept orders
                  </Typography>
                  <AcceptOrdersTable />
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ color: "#7B029E", fontWeight: 500 }}>
                    History of Orders
                  </Typography>
                  <HistoryOrders />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* dialog */}
    </div>
  );
}

export default Dashboard;

import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Chip, Divider, Typography } from "@mui/material";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { BallTriangle } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { useAppStore } from "../../appStore";

export default function AcceptOrdersTable() {
  const setOngoingOrders = useAppStore((state) => state.setOngoingOrders);
  const navigate = useNavigate();
  const [orders, setOrders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [timer, setTimer] = React.useState(100); // 5 minutes in seconds
  const [isOrderExpired, setIsOrderExpired] = useState(false);
  const [isMounted, setIsMounted] = useState(false); // New state for checking if component is mounted
  const [orderPlacedTime, setOrderPlacedTime] = React.useState(null);
  const { vendorId, setVendorId } = useAppStore();

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(API_PROD + "/order/getVendorOrders", {
        headers,
      });
      // console.log("orders", response.data.vendorOrders);
      setOrders(response?.data?.vendorOrders);
      setIsLoading(false);

      if (response?.data?.vendorOrders?.length > 0) {
        const placedTime = new Date(response.data.vendorOrders[0].createdAt);
        setOrderPlacedTime(placedTime);
      }
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  // const startTimer = () => {
  //   const interval = setInterval(() => {
  //     setTimer((prevTimer) => prevTimer - 1);
  //     if (timer === 0) {
  //       setIsOrderExpired(true);
  //       clearInterval(interval);
  //       const orderId = orders[0]?._id; // Assuming the first order in the orders array should be cancelled
  //       if (orderId) {
  //         cancelOrder(orderId); // Call cancelOrder with the order ID
  //       }
  //     }
  //   }, 1000);
  //   return interval;
  // };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          setIsOrderExpired(true);
          clearInterval(interval);
          const orderId = orders[0]?._id;
          if (orderId) {
            cancelOrder(orderId);
          }
        }
        return prevTimer - 1;
      });
    }, 1000);
    return interval;
  };

  React.useEffect(() => {
    const socket = io("http://13.51.255.37:5000"); // Replace with your server URL

    socket.on("connect", () => {
      console.log("Connected to server:", socket.id);
      console.log("Connected to server vendor id:", vendorId);

      socket.emit("joinVendorRoom", { vendorId: vendorId });
      // Replace with the actual vendor ID
    });

    socket.on("newOrder", (newOrder) => {
      console.log("New order received:", newOrder);
      setOrders(newOrder);
      setIsLoading(false);
    });

    setIsMounted(true); // Set isMounted to true on component mount

    if (isMounted) {
      fetchOrders(); // Fetch orders when component is mounted
    }

    // Timer logic
    if (isMounted && orderPlacedTime) {
      const currentTime = new Date();
      const timeDiff = Math.floor((currentTime - orderPlacedTime) / 1000); // Calculate time difference in seconds
      const initialTimer = 300 - timeDiff; // Set initial timer based on time difference
      setTimer(initialTimer > 0 ? initialTimer : 0); // Ensure the timer is not negative
      if (initialTimer > 0) {
        const interval = startTimer();
        return () => {
          clearInterval(interval);
        };
      } else {
        setIsOrderExpired(true);
        const orderId = orders[0]?._id;
        if (orderId) {
          cancelOrder(orderId);
        }
      }
    }

    // return () => {
    //   socket.disconnect(); // Clean up the socket connection when the component is unmounted
    // };

    // fetchOrders();
  }, [isMounted, orderPlacedTime]);

  // useEffect(() => {
  //   if (timer === 0) {
  //     setIsOrderExpired(true);
  //     const orderId = orders[0]?._id; // Assuming the first order in the orders array should be cancelled
  //     if (orderId) {
  //       cancelOrder(orderId); // Call cancelOrder with the order ID
  //       console.log("orderId", orderId);
  //     }
  //   }
  // }, [timer]);

  // auto cacnel order
  //denied
  const cancelOrder = async (id) => {
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    try {
      const response = await axios.put(
        API_PROD + `/order/updateOrder/${id}`,
        { status: "notaccepted" },
        { headers }
      );
      toast.success("You have not accepted the order.");
      // Update the status of the denied order in the orders state array
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === id ? { ...order, status: "notaccepted" } : order
        )
      );
      fetchOrders();
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    }
    navigate("/dashboard");
  };

  const fetchOngoingOrders = async () => {
    try {
      const response = await axios.get(
        API_PROD + "/order/vendorOngoingOrders",
        {
          headers,
        }
      );
      // console.log("ongogingorders", response.data);
      setOngoingOrders(response?.data?.ongoingOrders);
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  // accept orders
  const handleAccept = async (id) => {
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    try {
      const response = await axios.put(
        API_PROD + `/order/updateOrder/${id}`,
        { status: "accepted" },
        { headers }
      );
      toast.success("Order Accepetd.");
      // Remove the accepted order from the orders state array
      setOrders((prevOrders) => prevOrders.filter((order) => order._id !== id));
      fetchOngoingOrders();
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    }
    navigate("/dashboard");
  };

  // console.log("ofresrd", orders);
  return (
    <>
      {isLoading ? (
        <Box mb={2}>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Box>
      ) : (
        ""
      )}

      {orders.length === 0 && "No Orders found"}

      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            mt: 3,
            width: "auto",
            height: "auto",
            border: "1px solid #CCCCCC",
            borderRadius: 6,
          },
        }}
      >
        <Paper variant="outlined">
          {orders
            .filter((row) => row.status !== "accepted")
            .map((row, index) => {
              if (row.status === "accepted") {
                return null; // Skip rendering the row if the status is "accepted"
              }
              if (isOrderExpired && index === 0) {
                return null; // Skip rendering the canceled order row
              }
              return (
                <Box
                  p={1}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f7f7f5",
                      borderRadius: 6,
                    },
                  }}
                  key={row._id}
                >
                  <Box sx={{ display: "flex", padding: 1 }}>
                    <Typography variant="subtitle2" sx={{}}>
                      {index + 1}.
                    </Typography>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="subtitle2" sx={{}}>
                          ID:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#7B029E" }}
                        >
                          {" "}
                          {row._id.slice(2, 8)}
                        </Typography>
                      </Box>

                      <Typography variant="caption" sx={{ color: "#808080" }}>
                        Order pickup time 11:00 Am{" "}
                      </Typography>
                    </Box>
                    {/* 2nd row */}
                    {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", ml: 2 }}>
                                <Avatar sx={{ width: 38, height: 38, mr: 1 }}><img src='Ellipse-9.png' alt='' /></Avatar>
                                <Box><Typography sx={{ mt: "-4px" }}> {row.name}</Typography>

                                </Box>
                            </Box> */}
                    {/* 3rd row */}
                    <Box sx={{ ml: 1.5 }}>
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="subtitle2">Phone:</Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#000000" }}
                        >
                          {" "}
                          {row?.user?.phonenumber}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="subtitle2">Email:</Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#000000" }}
                        >
                          {" "}
                          {row?.user?.email}
                        </Typography>
                      </Box>
                    </Box>

                    {/* 4th row */}
                    <Box sx={{ ml: 1.5 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "#000000", textAlign: "center" }}
                      >
                        {" "}
                        € {row.orderPrice}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: "#000000" }}>
                        {" "}
                        payment in online mode
                        {/* {row.paymentmode} */}
                      </Typography>
                    </Box>

                    {/* new */}
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography ml={1}>
                        {" "}
                        {row?.products?.map((i) => (
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: "#F9F5FF",
                              color: "#6941C6",
                              fontWeight: "bold",
                            }}
                            label={i?.product?.name}
                          />
                        ))}
                      </Typography>
                    </Box>

                    {/* timming */}
                    <Box sx={{ ml: 2 }}>
                      {isOrderExpired ? (
                        ""
                      ) : (
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#000000" }}
                        >
                          Timer:{" "}
                        </Typography>
                      )}
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                          ml: 1,
                        }}
                      >
                        {isOrderExpired
                          ? "Order Expired"
                          : `${Math.floor(timer / 60)}:${timer % 60}`}
                      </Typography>
                    </Box>

                    {/* 5th row */}
                    {/* 5th row */}
                    {isOrderExpired ? (
                      ""
                    ) : (
                      <Box
                        onClick={() => cancelOrder(row._id)}
                        sx={{ ml: 3, textAlign: "center", cursor: "pointer" }}
                      >
                        <Typography variant="subtitle2">
                          <img src="cross.png" width={25} alt="" />
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#808080", fontSize: "12px" }}
                        >
                          Cancel
                        </Typography>{" "}
                      </Box>
                    )}
                    {/* 6th row */}
                    {isOrderExpired ? (
                      ""
                    ) : (
                      <Box
                        onClick={() => handleAccept(row._id)}
                        sx={{
                          ml: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Typography variant="subtitle2">
                          <img src="tick.png" width={25} alt="" />
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#808080", fontSize: "12px" }}
                        >
                          Accept
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Divider />
                </Box>
              );
            })}
        </Paper>
      </Box>
    </>
  );
}

import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useAppStore } from "../../appStore";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #7B029E",
  borderRadius: 1.5,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  pt: 2,
  px: 4,
  pb: 3,
};
function Ingredients({ onCloseEvent }) {
  const { setIngredients } = useAppStore(); // Access Zustand store
  const [ingredient, setIngredient] = useState("");

  const handleSave = () => {
    if (ingredient.trim() !== "") {
      setIngredients(ingredient);
      setIngredient("");
    }
    onCloseEvent();
  };

  return (
    <div>
      <Box sx={{ ...style, width: { xs: 250, sm: 340 } }}>
        <Typography sx={{ color: "#7B029E" }}>Type Ingredient</Typography>
        <Box mt={2}>
          <TextField
            value={ingredient}
            onChange={(e) => setIngredient(e.target.value)}
            fullWidth
            size="small"
            placeholder="type ingredient"
          />
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            sx={{
              float: "right",
              backgroundColor: "#7B029E",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#7B029E",
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default Ingredients;

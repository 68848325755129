import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate, useParams } from "react-router-dom";
import Ingredients from "./Ingredients";
import { useState } from "react";
import Parameters from "./Parameters";
import { useAppStore } from "../../appStore";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { BallTriangle } from "react-loader-spinner";
import { useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import EuroIcon from "@mui/icons-material/Euro";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: 300,
  height: 200,
  border: "1px solid #BEBEBE",
  borderRadius: 8,
  overflow: "hidden", // Add this to hide overflowing content

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Add this to maintain aspect ratio and cover the container
  },
}));
const CameraIcon = styled(CameraAltIcon)(({ theme }) => ({
  position: "absolute",
  zIndex: 999,
  left: "50%",
  transform: "translateX(-50%)",
  width: 30,
  height: 30,
  backgroundColor: "#DEC0E7",
  borderRadius: "50%",
  padding: 5,
  boxShadow: theme.shadows[1],
  cursor: "pointer",
}));
const DeleteIconButton = styled(DeleteIcon)(({ theme }) => ({
  position: "absolute",
  zIndex: 999,
  left: "50%", // Updated right position to 0
  transform: "translateX(-50%)",
  bottom: 0,
  width: 30,
  height: 30,
  backgroundColor: "#DEC0E7",
  borderRadius: "50%",
  padding: 5,
  boxShadow: theme.shadows[1],
  cursor: "pointer",
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function EditProductComponent() {
  const navigate = useNavigate();
  const params = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    ingredients,
    setIngredients,
    setEditIngredients,
    setIngredientsEmpty,
  } = useAppStore(); // Access Zustand store data
  const { parameter, setParameter, setEditParameter, setParameterEmpty } =
    useAppStore();
  const { category, setCategory, categoryid, setCategoryId } = useAppStore();
  const [open, setOpen] = useState(false);
  const [openParameter, setOpenParameter] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productStatus, setProductStatus] = useState();
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [discountValue, setDiscountValue] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [products, setProducts] = useState();

  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null); // Selected category state
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleChipClick = (chip) => {
    setProductStatus(chip);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
    navigate("/inventory");
  };

  const handleOpenParameter = () => {
    setOpenParameter(true);
  };

  const handleCloseParameter = () => {
    setOpenParameter(false);
  };

  const handleDiscountValueChange = (e) => {
    setDiscountValue(e.target.value);
  };

  const openCat = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseCat = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCategoryId(category._id);
    console.log("id", category._id);

    handleClose();
  };

  // const handleCategoryClick = (e) => {
  //   setCategory(e.target.value);
  //   // setSelectedCategory(category);
  //   // setCategoryId(category._id);
  //   console.log("id", category._id);
  // };

  // // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  //image upload
  const handleImageChange = async (e) => {
    setSelectedImage(e.target.files[0]);
    let tokenStr = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const response = await axios.post(API_PROD + `/uploadImage/`, formData, {
        headers: { accesstoken: tokenStr },
      });
      if (response.data.status === "success") {
        // Handle success case
        let data = response.data;
        setUploadUrl(data.fileUrl);
      }
    } catch (error) {
      // Handle error case
      toast.error("An error occurred while uploading your file.");
    }
  };

  const editProduct = async () => {
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    let formData = {
      name: productName,
      price: productPrice,
      status: productStatus,
      discountApplicable: applyDiscount,
      discountValue: discountValue,
      description: productDescription,
      ingredients: ingredients,
      parameters: parameter,
      category: categoryid,
      images: [uploadUrl],
    };
    try {
      const response = await axios.put(
        API_PROD + `/product/updateProduct/${products._id}`,
        // `${base_url}category/updateCategory/${category._id}`,
        formData,
        { headers }
      );
      if (response.data.status === "success") {
        // Handle success case
        let data = response.data;
        toast.success("Product updated successfully.");
        navigate("/inventory");
      }
    } catch (error) {
      // Handle error case
      toast.error("An error occurred while submitting your file.");
    }
  };
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
    // getSingleCategory();
  }, [selectedImage]);

  // getSingle
  const fetchProducts = async () => {
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    try {
      const response = await axios.get(
        API_PROD + `/product/getSingleProduct/${params.id}`,
        {
          headers,
        }
      );

      // const productData = response?.data?.product;
      // console.log("productsEdit", productData);

      // setProducts(productData);

      // console.log("productsEdit", response.data.product);
      setProducts(response?.data?.product);
      // setProductName(products?.name);
      // setProductPrice(products?.price);
      // setProductStatus(products?.status);
      // setApplyDiscount(products?.discountApplicable);
      // setImageUrl(products?.images[0]);
      // setUploadUrl(products?.images[0]);
      // setProductDescription(products?.description);
      setIsLoading(false);
      // console.log("pro", products);
    } catch (error) {
      setIsLoading(true);
      // console.error("Error: ", error);
    }
  };

  React.useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    if (products) {
      setProductName(products.name);
      setProductPrice(products.price);
      setProductStatus(products.status);
      setApplyDiscount(products.discountApplicable);
      setDiscountValue(products.discountValue);
      setCategoryId(products.categoryid);
      setImageUrl(products.images[0]);
      setEditIngredients(products.ingredients);
      setEditParameter(products.parameters);
      setUploadUrl(products.images[0]);
      setProductDescription(products.description);
      setIsLoading(false);
    }
  }, [products]);

  const handleIngredientChange = (index, value) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients[index] = value;
    setIngredients(updatedIngredients);
  };

  const handleParameterChange = (index, value) => {
    const updatedParameters = [...parameter];
    updatedParameters[index] = value;
    setParameter(updatedParameters);
  };

  // image delete
  const handleDeleteImage = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    // Delete logic
    setSelectedImage(null);
    setPreviewImage("");
    setUploadUrl("");
    setImageUrl("");
    setShowDeleteConfirmation(false);
    toast.success("image deleted successfully.");
    console.log("delteImage", imageUrl);
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div>
      {isLoading ? (
        <Box mb={2}>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Box>
      ) : (
        <Box>
          <Box>
            <Box sx={{ color: "#7B029E" }}>
              <Typography sx={{ fontWeight: "bold" }}>Edit Product</Typography>
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Box sx={{ display: "flex" }} mt={2}>
                    <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
                    <Box ml={6}>
                      :
                      <TextField
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        variant="standard"
                        size="small"
                      />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }} mt={2}>
                    <Typography sx={{ fontWeight: "bold" }}>Price</Typography>
                    <Box ml={7}>
                      :
                      <TextField
                        value={productPrice}
                        onChange={(e) => setProductPrice(e.target.value)}
                        variant="standard"
                        size="small"
                      />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }} mt={2}>
                    <Typography sx={{ fontWeight: "bold" }}>Status</Typography>

                    <Box ml={6}>
                      <Stack direction="row" spacing={5}>
                        :{" "}
                        <Chip
                          label="Available"
                          sx={{
                            borderRadius: 2,
                            ml: 1,
                            backgroundColor:
                              productStatus === "available" ? "green" : null,
                            "&:hover": {
                              backgroundColor:
                                productStatus === "available" ? "green" : null,
                            },
                            color:
                              productStatus === "available" ? "white" : null,
                            cursor: "pointer",
                          }}
                          onClick={() => handleChipClick("available")}
                        />
                        <Chip
                          label="Unavailable"
                          sx={{
                            borderRadius: 2,
                            backgroundColor:
                              productStatus === "unavailable" ? "red" : null,
                            "&:hover": {
                              backgroundColor:
                                productStatus === "unavailable" ? "red" : null,
                            },
                            color:
                              productStatus === "unavailable" ? "white" : null,
                            cursor: "pointer",
                          }}
                          onClick={() => handleChipClick("unavailable")}
                        />
                      </Stack>
                    </Box>
                  </Box>
                  {/* inventory components */}
                  <Box mt={2}>
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold", color: "#7B029E" }}
                      >
                        Category
                      </Typography>
                      <Box ml={3.3}>
                        :
                        <Button
                          size="small"
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openCat ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon />}
                          sx={{
                            backgroundColor: "#7B029E",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            borderRadius: 3,
                            "&:hover": {
                              background: "#7B029E",
                            },
                            ml: 1,
                          }}
                        >
                          {selectedCategory
                            ? selectedCategory.name
                            : products?.category
                            ? products?.category?.name
                            : "Select Category"}
                        </Button>
                      </Box>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={openCat}
                        onClose={handleCloseCat}
                      >
                        {category.map((category) => (
                          <MenuItem
                            key={category._id}
                            onClick={() => handleCategoryClick(category)}
                            disableRipple
                            value={category._id}
                            selected={
                              selectedCategory &&
                              selectedCategory._id === category._id
                            }
                          >
                            {category.name}
                          </MenuItem>
                        ))}
                      </StyledMenu>
                    </div>
                  </Box>
                </Box>
                {/* image */}
                <Box ml={8}>
                  <ImageContainer>
                    <Box>
                      <input
                        type="file"
                        id="select-image"
                        name="image"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="select-image">
                        <Tooltip title="click to upload image" placement="top">
                          <CameraIcon />
                        </Tooltip>
                      </label>
                    </Box>

                    <DeleteIconButton onClick={handleDeleteImage} />

                    {imageUrl ? (
                      <img src={imageUrl} alt="" />
                    ) : (
                      <img src={products?.images[0] || "/noimage.png"} />
                    )}
                  </ImageContainer>
                  {/* <Box mt={3} sx={{ display: "flex" }}>
                    <Box>
                      <input
                        type="file"
                        id="select-image"
                        name="image"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="select-image">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          component="span"
                          // sx={{ marginLeft: 18 }}
                        >
                          Upload New Image
                        </Button>
                      </label>
                    </Box>
                    <Box ml={4}>
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt=""
                          style={{
                            height: "200px",
                          }}
                        />
                      ) : (
                        <img
                          src={products?.images[0]}
                          alt="no image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Box>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* apply discount */}
          <Box sx={{ display: "flex", alignItems: "center", ml: -1.3, mt: 2 }}>
            <Checkbox
              {...label}
              size="small"
              checked={applyDiscount}
              // value={applyDiscount}
              onChange={(e) => setApplyDiscount(e.target.checked)}
            />
            <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}>
              Apply Discount
            </Typography>
            {applyDiscount && (
              <Box ml={2}>
                <TextField
                  sx={{ width: "200px" }}
                  value={discountValue}
                  onChange={handleDiscountValueChange}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          </Box>

          {/* description */}
          <Box mt={3}>
            <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}>
              Description{" "}
            </Typography>
            <TextField
              fullWidth
              multiline
              focused
              color="secondary"
              placeholder="write description here...."
              sx={{
                width: "50%",
                mt: 0.5,
              }}
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
            />
          </Box>

          {/* ingredients */}
          <Box mt={4}>
            <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}>
              Ingredients{" "}
            </Typography>
            <div>
              <Box
                height="30%" // Each ingredient occupies 25% of the container width
                sx={{
                  width: "100%",
                }}
                display="grid"
                gridTemplateColumns="repeat(2,0.08fr)"
                gridGap={1}
                gridAutoFlow
              >
                {ingredients?.map((ingredient, index) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption">{index + 1}.</Typography>
                    <Typography
                      variant="caption"
                      onChange={handleIngredientChange}
                    >
                      {ingredient}
                    </Typography>
                  </Box>
                ))}
                <img
                  onClick={handleOpen}
                  src="/plus-icon.png"
                  alt=""
                  width={16}
                  style={{ marginRight: 14, cursor: "pointer" }}
                />
              </Box>
            </div>
          </Box>

          {/* parameters */}
          <Box mt={4}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#7B029E", fontWeight: "bold" }}
            >
              Parameters{" "}
            </Typography>
            <div>
              <Box
                height="30%" // Each ingredient occupies 25% of the container width
                sx={{
                  width: "100%",
                }}
                display="grid"
                gridTemplateColumns="repeat(5,1fr)"
                gridGap={1}
                gridAutoFlow
              >
                {Object.entries(parameter || {}).map(([key, value]) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#7B029E", fontWeight: "bold", mr: 1.5 }}
                    >
                      {key}
                    </Typography>
                    <Typography variant="subtitle2">:{value}</Typography>
                  </Box>
                ))}
                <img
                  onClick={handleOpenParameter}
                  src="/plus-icon.png"
                  alt=""
                  width={16}
                  style={{
                    marginRight: 14,
                    cursor: "pointer",
                    marginTop: 7,
                    marginLeft: 4,
                  }}
                />
              </Box>
            </div>
          </Box>
          {/* buttons */}
          <Box mt={5} sx={{ display: "flex", width: "30%" }}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                background: "none",
                border: "1px solid #B8B6B6 ",
                color: "#7B029E",
                "&:hover": {
                  background: "#FFFFFF",
                },
                boxShadow: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={editProduct}
              sx={{
                background: "#7B029E",
                ml: 2,
                textTransform: "capitalize",
                color: "#FFFFFF",
                borderRadius: 2,
                "&:hover": {
                  background: "#7B029E",
                },
              }}
            >
              Save Changes
            </Button>
          </Box>
          {/* ingredient modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Ingredients onCloseEvent={handleClose} />
          </Modal>
          {/* parameters modal */}
          <Modal
            open={openParameter}
            onClose={handleCloseParameter}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Parameters onCloseEvent={handleCloseParameter} />
          </Modal>
        </Box>
      )}

      <Dialog open={showDeleteConfirmation} onClose={handleCancelDelete}>
        <DialogTitle>Delete Image</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the image?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelDelete}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDelete}
            sx={{ textTransform: "capitalize" }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditProductComponent;

import { create } from "zustand";
import { persist } from "zustand/middleware";
let appStore = (set) => ({
  dopen: true,
  updateOpen: (dopen) => set((state) => ({ dopen: dopen })),
  userid: "",
  setUserid: (userid) => set((state) => ({ userid: userid })),
  setUserrow: (userrow) => set((state) => ({ userrow: userrow })),
  vendorrow: [],
  setVendorrow: (vendorrow) => set((state) => ({ vendorrow: vendorrow })),

  //   vendor
  category: [],
  setCategory: (category) => set((state) => ({ category: category })),
  ongoingOrders: [],
  setOngoingOrders: (value) => set((state) => ({ ongoingOrders: value })),

  categoryid: "",
  setCategoryId: (categoryid) => set((state) => ({ categoryid: categoryid })),

  phoneNumber: "",
  setPhoneNumber: (phoneNumber) =>
    set((state) => ({ phoneNumber: phoneNumber })),
  ingredients: [],
  setIngredientsEmpty: (value) => set((state) => ({ ingredients: value })),
  setEditIngredients: (value) => set((state) => ({ ingredients: value })),
  setIngredients: (value) =>
    set((state) => ({ ingredients: [...state.ingredients, value] })),
  parameter: {},
  setParameterEmpty: (value) => set((state) => ({ parameter: value })),
  setEditParameter: (value) => set((state) => ({ parameter: value })),
  setParameter: (key, value) =>
    set((state) => ({ parameter: { ...state.parameter, [key]: value } })),

  otpResponse: Boolean,
  setOtpResponse: (otpResponse) => set((state) => ({ otpResponse })),

  isVerified: Boolean,
  setIsVerified: (isVerified) => set((state) => ({ isVerified })),

  openDialog: Boolean,
  setOpenDialog: (openDialog) => set((state) => ({ openDialog })),

  vendorId: "",
  setVendorId: (vendorId) => set((state) => ({ vendorId })),
  // location:{},
  // setLocation:() =>
  name: "",
  setName: (name) => set((state) => ({ name })),

  accountType: "",
  setAccountType: (accountType) => set((state) => ({ accountType })),

  accountNumber: "",
  setAccountNumber: (accountNumber) => set((state) => ({ accountNumber })),

  ifscCode: "",
  setIfscCode: (ifscCode) => set((state) => ({ ifscCode })),

  name2: "",
  setName2: (name2) => set((state) => ({ name2 })),

  accountType2: "",
  setAccountType2: (accountType2) => set((state) => ({ accountType2 })),

  accountNumber2: "",
  setAccountNumber2: (accountNumber2) => set((state) => ({ accountNumber2 })),

  ifscCode2: "",
  setIfscCode2: (ifscCode2) => set((state) => ({ ifscCode2 })),

  singleOrder: "",
  setSingleOrder: (singleOrder) =>
    set((state) => ({ singleOrder: singleOrder })),

  selectedFiles: null,
  setSelectedFiles: (selectedFiles) =>
    set((state) => ({ selectedFiles: selectedFiles })),

  pdfFileUrl: null,
  setPdfFileUrl: (pdfFileUrl) => set((state) => ({ pdfFileUrl: pdfFileUrl })),

  uploadPdfUrl: null,
  setUploadPdfUrl: (uploadPdfUrl) =>
    set((state) => ({ uploadPdfUrl: uploadPdfUrl })),
});
appStore = persist(appStore, { name: "botoapp_store" });
export const useAppStore = create(appStore);

import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { useAppStore } from "../../appStore";
import "./phonefiledCss.css";
import { Box, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";

const PhoneField = () => {
  // const [phoneNumber, setPhoneNumber] = useState("");
  const setPhoneNumber = useAppStore((state) => state.setPhoneNumber);
  const phoneNumber = useAppStore((state) => state.phoneNumber);
  const setOtpResponse = useAppStore((state) => state.setOtpResponse);
  const otpResponse = useAppStore((state) => state.otpResponse);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber("+" + value);
  };

  // Function to encrypt the phone number
  const encryptPhoneNumber = (phoneNumber) => {
    // Replace the middle digits with 'x' characters
    const encryptedDigits = phoneNumber.slice(4, -2).replace(/\d/g, "x");

    // Reconstruct the encrypted phone number
    const encryptedPhoneNumber =
      phoneNumber.slice(0, 5) + encryptedDigits + phoneNumber.slice(-2);

    return encryptedPhoneNumber;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const role = "vendor";
    const apikey = `${phoneNumber}:${role}`;
    const encodedApiKey = btoa(apikey);

    const headers = {
      apikey: encodedApiKey,
    };
    // console.log("headers", headers);
    axios
      .post(API_PROD + "/auth/login", {}, { headers })
      .then((response) => {
        // Handle the API response
        setOtpResponse(response.data.sucess);
        // console.log(response.data.sucess);
        // const encryptedPhoneNumber = phoneNumber.replace(/.(?=.{4})/g, "x");
        const encryptedPhoneNumber = encryptPhoneNumber(phoneNumber);
        toast.success(`OTP sent to phone number ${encryptedPhoneNumber}`);
        // toast.success(`otp sent to phonenumber ${phoneNumber} `);
        // console.log("otp response", response.data);
      })
      .catch((error) => {
        // Handle errors
        toast.error(error.message);
        console.error(error.message);
      });
  };

  return (
    <div>
      <PhoneInput
        international
        country={"in"}
        containerClass="custom-phone-input"
        inputClass="custom-phone-input"
        buttonClass="custom-phone-input-button"
        inputStyle={{
          width: "100%",
          height: "50px",
          border: "none",
          fontSize: "16px",
        }}
        buttonStyle={{ border: "none", background: "none" }}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        placeholder="Mobile Number"
      />
      <Box sx={{ textAlign: "end" }}>
        <Button
          size="small"
          float="right"
          sx={{ textTransform: "capitalize" }}
          onClick={handleFormSubmit}
          disabled={!phoneNumber}
        >
          Send OTP
        </Button>
      </Box>
    </div>
  );
};

export default PhoneField;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  TablePagination,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { useState } from "react";
import { BallTriangle } from "react-loader-spinner";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EAECF0",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(API_PROD + "/order/getVendorAllOrders", {
        headers,
      });
      console.log("transaction", response.data);
      setTransactions(response?.data?.vendorOrders);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTransactions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {isLoading ? (
        <Box mb={2}>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">
                  Status
                  <i
                    class="fa-solid fa-arrow-down fa-sm"
                    style={{ marginLeft: 10 }}
                  ></i>
                </StyledTableCell>
                <StyledTableCell align="center">Orders</StyledTableCell>
                <StyledTableCell align="center">
                  Amount{" "}
                  <i
                    class="fa-solid fa-arrow-down fa-sm"
                    style={{ marginLeft: 8 }}
                  ></i>
                </StyledTableCell>
                <StyledTableCell align="center">Payment method</StyledTableCell>
                <StyledTableCell align="center">
                  Customer contact
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {transactions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Avatar sx={{ width: 40, height: 40, mr: 1 }}>
                  <img src="Avatar.png" alt="" />
                </Avatar> */}
                      <Box>
                        <Typography variant="subtitle2">
                          {" "}
                          {row.user.name}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="subtitle2">ID:</Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#7B029E", fontWeight: "bold" }}
                          >
                            {" "}
                            {row._id.slice(2, 8)}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledTableCell>

                    {row.paymentInfo.status === "paid" ? (
                      <StyledTableCell align="center">
                        {" "}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#ECFDF3",
                            color: "#027A48",
                            fontWeight: "bold",
                          }}
                          icon={
                            <FiberManualRecordIcon
                              color="success"
                              sx={{ width: "10px" }}
                            />
                          }
                          label={row?.paymentInfo.status}
                        />
                      </StyledTableCell>
                    ) : (
                      ""
                    )}
                    {row.paymentInfo.status === "refund" ? (
                      <StyledTableCell align="center">
                        {" "}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#FDECEC",
                            color: "#7A0202",
                            fontWeight: "bold",
                          }}
                          icon={
                            <FiberManualRecordIcon
                              color="error"
                              sx={{ width: "10px" }}
                            />
                          }
                          label={row?.paymentInfo.status}
                        />
                      </StyledTableCell>
                    ) : (
                      ""
                    )}
                    {row.paymentInfo.status === "pending" ? (
                      <StyledTableCell align="center">
                        {" "}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#FDF8EC",
                            color: "#7A4302",
                            fontWeight: "bold",
                          }}
                          icon={
                            <FiberManualRecordIcon
                              color="warning"
                              sx={{ width: "10px" }}
                            />
                          }
                          label={row?.paymentInfo.status}
                        />
                      </StyledTableCell>
                    ) : (
                      ""
                    )}
                    {/* orders name */}
                    <StyledTableCell align="center">
                      {row?.products?.map((i) => (
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#F9F5FF",
                            color: "#6941C6",
                            fontWeight: "bold",
                          }}
                          label={i.product.name}
                        />
                      ))}
                    </StyledTableCell>

                    {/* order price */}
                    {row.paymentInfo.status === "paid" ? (
                      <StyledTableCell align="center">
                        {" "}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#ECFDF3",
                            color: "#027A48",
                            fontWeight: "bold",
                          }}
                          label={`€ ${row?.orderPrice}`}
                        />
                      </StyledTableCell>
                    ) : (
                      ""
                    )}
                    {row.paymentInfo.status === "refund" ? (
                      <StyledTableCell align="center">
                        {" "}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#FDECEC",
                            color: "#B71212",
                            fontWeight: "bold",
                          }}
                          label={`€ ${row?.orderPrice}`}
                        />
                      </StyledTableCell>
                    ) : (
                      ""
                    )}
                    {row.paymentInfo.status === "pending" ? (
                      <StyledTableCell align="center">
                        {" "}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: "#FDF8EC",
                            color: "#7A4302",
                            fontWeight: "bold",
                          }}
                          label={`€ ${row?.orderPrice}`}
                        />
                      </StyledTableCell>
                    ) : (
                      ""
                    )}

                    {/* payment method */}
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {row.paymentInfo.paymentMethod}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "13px", fontWeight: "bold" }}
                      >
                        {row.user.phonenumber}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Divider />
        </TableContainer>
      )}
    </>
  );
}

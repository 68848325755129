import React, { useState } from "react";
import MyOrdersTable from "../../components/myOrdersTable/MyOrdersTable";
import Sidebar from "../../components/sidebar/SideBar";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { toast } from "react-toastify";
import { useAppStore } from "../../appStore";
import { useNavigate } from "react-router-dom";

export default function WithdrawAmount() {
  const navigate = useNavigate();
  const [selectedChip, setSelectedChip] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [amount, setAmount] = useState("");
  const {
    accountNumber,
    ifscCode,
    accountType,
    accountNumber2,
    ifscCode2,
    accountType2,
  } = useAppStore();
  const accountTypes = [
    { value: "primary", label: "Primary account" },
    { value: "secondary", label: "Secondary account" },
  ];

  // const handleChipClick = (chip) => {
  //   setAmount(chip);
  //   setSelectedChip(chip);
  // };

  const handleChipClick = (chip) => {
    setSelectedChip((prevChip) => (prevChip === chip ? null : chip));
    setAmount(chip);
  };

  const handleRadioChange = (event) => {
    setSelectedAccountType(event.target.value);
    setAmount(""); // Reset amount to null
    setSelectedChip(null); // Reset selectedChip to null
  };

  const handleWithdrawClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //header tokens
  let tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };
  const handleConfirmWithdraw = async () => {
    if (selectedAccountType === "primary") {
      // Primary account selected, use accountNumber and ifscCode
      console.log("selctedType", selectedAccountType);
      console.log("Account Number:", accountNumber);
      console.log("IFSC Code:", ifscCode);
      console.log("Account Type:", accountType);
      // Make API call with accountNumber and ifscCode
      try {
        const response = await axios.post(
          API_PROD + `/settlement/createSettlement`,
          {
            bankDetails: {
              accountNumber: accountNumber,
              ifscCode: ifscCode,
            },
            amount: `€ ${amount}`,
          },
          { headers }
        );
        console.log("withdrawResponse", response.data);
        toast.success("Amount successfully sent from primary account.");
      } catch (error) {
        // Handle error case
        toast.error(error.message);
      }
    } else if (selectedAccountType === "secondary") {
      // Secondary account selected, use accountNumber2 and ifscCode2
      console.log("selctedType2", selectedAccountType);
      console.log("Account Number:", accountNumber2);
      console.log("IFSC Code:", ifscCode2);
      console.log("Account Type:", accountType2);
      // Make API call with accountNumber2 and ifscCode2
      try {
        const response = await axios.post(
          API_PROD + `/settlement/createSettlement`,
          {
            bankDetails: {
              accountNumber: accountNumber2,
              ifscCode: ifscCode2,
            },
            amount: `€ ${amount}`,
          },
          { headers }
        );
        console.log("withdrawResponse", response.data);
        toast.success("Amount successfully sent from secondary account.");
      } catch (error) {
        // Handle error case
        toast.error(error.message);
      }
    }
    navigate("/mySettlements");

    // Reset form fields
    setSelectedAccountType(null);
    setAmount(""); // Reset amount to null
    setSelectedChip(null); // Reset selectedChip to null

    // Close the dialog
    setOpenDialog(false);
  };

  const handleAccountTypeChange = (event) => {
    setSelectedAccountType(event.target.value);
    setAmount("");
    setSelectedChip(null);
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ p: 1 }}>
          <Box height={90} />
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#7B029E" }}
            >
              Withdraw amount
            </Typography>
          </Box>
          <Box>
            <Box sx={{ textAlign: "start" }}>
              <FormControlLabel
                value="primary"
                control={<Radio />}
                label="Primary account"
                checked={selectedAccountType === "primary"}
                onChange={handleRadioChange}
              />
              <FormControlLabel
                value="secondary"
                control={<Radio />}
                label="Secondary account"
                checked={selectedAccountType === "secondary"}
                onChange={handleRadioChange}
              />
            </Box>
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Enter the amount
              </Typography>
              <TextField
                id="standard-basic"
                variant="standard"
                type="number"
                helperText="(enter numbers only)"
                value={amount}
                onChange={(event) => setAmount(event.target.value)}
                disabled={selectedChip !== null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={4}>
              <Stack direction="row" spacing={5}>
                <Chip
                  label="€ 150"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: selectedChip === "150" ? "#FFCC03" : null,
                    "&:hover": {
                      backgroundColor:
                        selectedChip === "150" ? "#FFCC03" : null,
                    },
                  }}
                  onClick={() => handleChipClick("150")}
                  // disabled={amount !== ""}
                />
                {/* Remaining Chip components */}
                <Chip
                  label="€ 250"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: selectedChip === "250" ? "#FFCC03" : null,
                    "&:hover": {
                      backgroundColor:
                        selectedChip === "250" ? "#FFCC03" : null,
                    },
                  }}
                  onClick={() => handleChipClick("250")}
                  // disabled={amount !== ""}
                />
                <Chip
                  label="€ 350"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: selectedChip === "350" ? "#FFCC03" : null,
                    "&:hover": {
                      backgroundColor:
                        selectedChip === "350" ? "#FFCC03" : null,
                    },
                  }}
                  onClick={() => handleChipClick("350")}
                  // disabled={amount !== ""}
                />
                <Chip
                  label="€ 450"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: selectedChip === "450" ? "#FFCC03" : null,
                    "&:hover": {
                      backgroundColor:
                        selectedChip === "450" ? "#FFCC03" : null,
                    },
                  }}
                  onClick={() => handleChipClick("450")}
                  // disabled={amount !== ""}
                />
              </Stack>
            </Box>

            <Box mt={4} sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  textTransform: "lowercase",
                  fontWeight: "bold",
                  background: "#7B029E",
                  color: "#ffffff",
                  letterSpacing: 0.5,
                  "&:hover": {
                    background: "#7B029E",
                  },
                }}
                onClick={handleWithdrawClick}
                disabled={
                  selectedAccountType === null ||
                  (selectedChip === null && amount === "")
                }
              >
                withdraw
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Would You like to withdraw money,please confirm?
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              color: "#FFFFFF",
              backgroundColor: "#7B029E",
              fontWeight: "bold",
              letterSpacing: 0.2,
              "&:hover": {
                backgroundColor: "#7B029E",
              },
            }}
            onClick={handleConfirmWithdraw}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

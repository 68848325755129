import React from 'react'
import Sidebar from '../../components/sidebar/SideBar'
import MyTransactionTable from '../../components/MyTransactionTable/MyTranscationTable'
import { Box, Button, Typography } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone';

function MyTranscation() {
    return (
        <div>

            <Box sx={{ display: "flex" }}>
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                    <Box height={90} />
                    <Typography variant='h5' sx={{ fontWeight: "bold", mb: 4 }}>My Transcations</Typography>
                    <MyTransactionTable />

                </Box>
                <Box sx={{ mt: "162px", mr: 2, ml: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: "#7B029E", padding: "12px 18px", '&:hover': {
                            background: "#7B029E",
                        }
                    }}>
                        <PhoneIcon sx={{ color: "#ffffff" }} />
                        <Box ml={1} >
                            <Typography variant='caption' sx={{ color: "#FFFFFF" }} >Contact Us</Typography> <br />
                            <Typography variant='subtitle2' sx={{ color: "#FFFFFF" }}>+91 234 xx 5667</Typography>
                        </Box>
                    </Button>
                </Box>
            </Box>
        </div >
    )
}

export default MyTranscation

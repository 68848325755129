import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import MenuCard from "./MenuCard";
import { useAppStore } from "../../appStore";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { BallTriangle } from "react-loader-spinner";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import MySettlements from "../../pages/MySettlements/MySettlements";

function BankDetailsCard() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [accountNumber, setAccountNumber] = useState("");
  // const [ifscCode, setIfscCode] = useState("");
  // const [accountType, setAccountType] = useState("");
  const [accounts, setAccounts] = useState([]);
  // const setAccountType = useAppStore((state) => state.setAccountType);
  // const accountType = useAppStore((state) => state.accountType);
  const setName = useAppStore((state) => state.setName);
  const name = useAppStore((state) => state.name);
  const setAccountNumber = useAppStore((state) => state.setAccountNumber);
  const accountNumber = useAppStore((state) => state.accountNumber);

  const setIfscCode = useAppStore((state) => state.setIfscCode);
  const ifscCode = useAppStore((state) => state.ifscCode);

  const setAccountType = useAppStore((state) => state.setAccountType);
  const accountType = useAppStore((state) => state.accountType);

  const setName2 = useAppStore((state) => state.setName2);
  const name2 = useAppStore((state) => state.name2);

  const setAccountNumber2 = useAppStore((state) => state.setAccountNumber2);
  const accountNumber2 = useAppStore((state) => state.accountNumber2);

  const setIfscCode2 = useAppStore((state) => state.setIfscCode2);
  const ifscCode2 = useAppStore((state) => state.ifscCode2);

  const setAccountType2 = useAppStore((state) => state.setAccountType2);
  const accountType2 = useAppStore((state) => state.accountType2);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState("Primary Account");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleClose();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setName("");
    setAccountNumber("");
    setIfscCode("");
    setAccountType("");
  };

  let tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };
  const handleAddAccount = async () => {
    const newAccount = {
      name: name,
      accountNumber: accountNumber,
      ifscCode: ifscCode,
      accountType: accountType,
    };
    // const newAccount2 = {
    //   name: name,
    //   accountNumber: accountNumber2,
    //   ifscCode: ifscCode2,
    //   accountType: accountType2,
    // };
    try {
      const response = await axios.post(
        API_PROD + `/vendor/addBankDetails`,
        newAccount,
        { headers }
      );

      console.log("bankdetails", response.data);
      toast.success("Bank details added successfully.");
      setName("");
      setAccountNumber("");
      setAccountType("");
      setIfscCode("");
      // setName2("");
      // setAccountNumber2("");
      // setAccountType2("");
      // setIfscCode2("");
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    }

    navigate("/MySettlements");
    handleClose();
  };

  const getBankDetails = async () => {
    try {
      const response = await axios.get(API_PROD + "/vendor/getBankDetails", {
        headers,
      });
      console.log("accounts", response?.data.bankDetails);
      setAccounts(response?.data.bankDetails);
      setAccountNumber(response.data.bankDetails[0].accountNumber);
      console.log("accountnumber1", accountNumber);

      setIfscCode(response.data.bankDetails[0].ifscCode);
      console.log("accountnumber1", ifscCode);

      setAccountType(response.data.bankDetails[0].accountType);
      console.log("accountnumber1", accountType);

      setAccountNumber2(response.data.bankDetails[1].accountNumber);
      console.log("accountnumber2", accountNumber2);

      setIfscCode2(response.data.bankDetails[1].ifscCode);
      console.log("accountnumber2", ifscCode2);

      setAccountType2(response.data.bankDetails[1].accountType);
      console.log("accountnumber2", accountType2);

      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#7B029E", fontWeight: "bold" }}
          >
            Bank Details
          </Typography>

          {accounts.length != 2 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ml: 28,
              }}
              onClick={handleClickOpen}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            >
              <img
                src="/plus-icon.png"
                alt=""
                width={20}
                onClick={handleClickOpen}
                style={{ marginRight: 14 }}
              />
              Add another account
            </Box>
          )}
        </Box>

        {isLoading ? (
          <Box mb={2}>
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </Box>
        ) : (
          ""
        )}

        <Grid container spacing={2}>
          {accounts.map((account, index) => (
            <Grid item sm={4} key={index}>
              <Box>
                <Box textAlign={"end"}>
                  <div>
                    <Button
                      id="account-button"
                      aria-controls={anchorEl ? "account-menu" : undefined}
                      aria-haspopup="true"
                      sx={{ textTransform: "capitalize", color: "#333333" }}
                      // onClick={handleClick}
                      // endIcon={<KeyboardArrowDownIcon />}
                    >
                      {account.accountType}
                    </Button>
                    <Menu
                      id="account-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem
                        onClick={() => handleOptionSelect("Primary Account")}
                      >
                        Primary Account
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleOptionSelect("Secondary Account")}
                      >
                        Secondary Account
                      </MenuItem>
                    </Menu>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: 320,
                      height: 160,
                      borderRadius: 3,
                    },
                  }}
                >
                  <Paper
                    sx={{
                      background:
                        "linear-gradient(180deg, #E691FF 0%, rgba(230, 145, 255, 0.67) 85.94%, rgba(230, 145, 255, 0.48) 100%)",
                      boxShadow:
                        "0px 4px 4px rgba(249, 255, 50, 0.25), inset 17.8667px -17.8667px 17.8667px rgba(165, 165, 165, 0.1), inset -17.8667px 17.8667px 17.8667px rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(17.8667px)",
                    }}
                  >
                    <Box p={2.5}>
                      <Typography variant="h6">{account.name},</Typography>
                      <Typography variant="subtitle1" mt={1}>
                        {account?.accountNumber &&
                          account.accountNumber.replace(/(.{4})/g, "$1 ")}
                      </Typography>
                      {/* <Box
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          € 2,500
                        </Typography>{" "}
                        <Typography variant="caption" ml={2}>
                          Available in bank account
                        </Typography>
                      </Box> */}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="subtitle1">BIC NO:</Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#333333", ml: 1 }}
                          >
                            {account.ifscCode}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            sx={{ fontFamily: "cursive", color: "#FFFFFF" }}
                          >
                            VISA
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item sm={4}></Grid>
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Account</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            size="small"
            label="Account Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
          <TextField
            size="small"
            label="IFSC Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={ifscCode}
            onChange={(e) => setIfscCode(e.target.value)}
          />
          <TextField
            size="small"
            label="Account Type"
            variant="outlined"
            fullWidth
            margin="normal"
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          />
          <Button
            size="small"
            variant="contained"
            onClick={handleClose}
            sx={{
              textTransform: "capitalize",
              mt: 2,
              background: "red",
              color: "white",
              "&:hover": {
                background: "red",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleAddAccount}
            sx={{ textTransform: "capitalize", ml: 2, mt: 2 }}
            disabled={
              name === "" ||
              accountNumber === "" ||
              ifscCode === "" ||
              accountType === ""
            }
          >
            Add
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BankDetailsCard;

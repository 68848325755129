import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import Ingredients from "./Ingredients";
import { useState } from "react";
import Parameters from "./Parameters";
import { useAppStore } from "../../appStore";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { toast } from "react-toastify";
import InventoryComponents from "../InventoryManagement/InventoryComponents";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { styled, alpha } from "@mui/material/styles";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: 300,
  height: 200,
  border: "1px solid #BEBEBE",
  borderRadius: 8,
  overflow: "hidden", // Add this to hide overflowing content

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Add this to maintain aspect ratio and cover the container
  },
}));
const CameraIcon = styled(CameraAltIcon)(({ theme }) => ({
  position: "absolute",
  zIndex: 999,
  left: "50%",
  transform: "translateX(-50%)",
  width: 30,
  height: 30,
  backgroundColor: "#DEC0E7",
  borderRadius: "50%",
  padding: 5,
  boxShadow: theme.shadows[1],
  cursor: "pointer",
}));

function AddProductComponent() {
  const navigate = useNavigate();
  const { ingredients, setIngredients } = useAppStore(); // Access Zustand store data
  const { parameter, setParameter } = useAppStore();
  const { setParameterEmpty } = useAppStore();
  const { setIngredientsEmpty } = useAppStore();
  const { categoryid, setCategoryId } = useAppStore();
  const [open, setOpen] = useState(false);
  const [openParameter, setOpenParameter] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productStatus, setProductStatus] = useState();
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [discountValue, setDiscountValue] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));

      // console.log("imageUrl", imageUrl);
    }
  }, [selectedImage]);
  useEffect(() => {
    setParameterEmpty({});
    setIngredientsEmpty([]);
  }, []);
  const handleChipClick = (chip) => {
    // setProductStatus(chip);
    setProductStatus(chip === "available" ? "available" : "unavailable");
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //image upload
  const handleImageChange = async (e) => {
    setSelectedImage(e.target.files[0]);
    let tokenStr = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const response = await axios.post(API_PROD + `/uploadImage/`, formData, {
        headers: { accesstoken: tokenStr },
      });
      if (response.data.status === "success") {
        // Handle success case
        let data = response.data;
        setUploadUrl(data.fileUrl);
      }
    } catch (error) {
      // Handle error case
      toast.error("An error occurred while uploading your file.");
    }
  };

  const handleApplyDiscountChange = (e) => {
    setApplyDiscount(e.target.checked);
  };

  const handleDiscountValueChange = (e) => {
    setDiscountValue(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
    navigate("/inventory");
  };

  const handleOpenParameter = () => {
    setOpenParameter(true);
  };

  const handleCloseParameter = () => {
    setOpenParameter(false);
  };

  const tokenStr = localStorage.getItem("accessToken");
  // console.log("tokenStr", tokenStr);
  const handleSaveChanges = () => {
    // Prepare the data to be sent in the request
    // const data = {};

    const headers = {
      accessToken: tokenStr,
    };
    let formData = {
      name: productName,
      price: productPrice,
      status: productStatus,
      discountApplicable: applyDiscount,
      discountValue: discountValue,
      description: productDescription,
      ingredients: ingredients,
      parameters: parameter,
      category: categoryid,
      images: [uploadUrl],
    };
    // Make the API call using Axios
    axios
      .post(API_PROD + "/product/createProduct", formData, { headers })
      .then((response) => {
        // Handle the successful response
        // console.log("products", response);
        toast.success("Product added successfully.");
        console.log("responseProduct", response);
        navigate("/inventory");
        // Optionally, you can perform any additional actions here, such as navigating to another page
        setOpen(false);
        setProductName("");
        setProductPrice("");
        setProductStatus();
        setApplyDiscount(false);
        setDiscountValue("");
        setProductDescription("");
        setCategoryId("");
        setIngredientsEmpty([]);
        setParameterEmpty({});
        setParameter({});
        setImageUrl([]);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <div>
      <Box>
        <Box sx={{ color: "#7B029E" }}>
          <Typography sx={{ fontWeight: "bold" }}>Add Product</Typography>
          <Box sx={{ display: "flex" }}>
            {/* prdouct details */}
            <Box>
              <Box sx={{ display: "flex" }} mt={2}>
                <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
                <Box ml={6}>
                  :
                  <TextField
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    variant="standard"
                    size="small"
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex" }} mt={2}>
                <Typography sx={{ fontWeight: "bold" }}>Price</Typography>
                <Box ml={7}>
                  :
                  <TextField
                    value={productPrice}
                    onChange={(e) => setProductPrice(e.target.value)}
                    variant="standard"
                    size="small"
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex" }} mt={2}>
                <Typography sx={{ fontWeight: "bold" }}>Status</Typography>

                <Box ml={6}>
                  <Stack direction="row" spacing={5}>
                    :{" "}
                    <Chip
                      label="Available"
                      sx={{
                        borderRadius: 2,
                        ml: 1,
                        backgroundColor:
                          productStatus === "available" ? "green" : null,
                        "&:hover": {
                          backgroundColor:
                            productStatus === "available" ? "green" : null,
                        },
                        color: productStatus === "available" ? "white" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => handleChipClick("available")}
                    />
                    <Chip
                      label="Unavailable"
                      sx={{
                        borderRadius: 2,
                        backgroundColor:
                          productStatus === "unavailable" ? "red" : null,
                        "&:hover": {
                          backgroundColor:
                            productStatus === "unavailable" ? "red" : null,
                        },
                        color: productStatus === "unavailable" ? "white" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => handleChipClick("unavailable")}
                    />
                  </Stack>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }} mt={3}>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>Category</Typography>
                </Box>
                <Box ml={3}>
                  :
                  <Box ml={2} mt={-3}>
                    <InventoryComponents />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* image */}
            <Box ml={8}>
              <ImageContainer>
                <Box>
                  <input
                    type="file"
                    id="select-image"
                    name="image"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="select-image">
                    <Tooltip title="click to upload image" placement="top">
                      <CameraIcon />
                    </Tooltip>
                  </label>
                </Box>
                {imageUrl && selectedImage && (
                  <img src={imageUrl} alt="no image found" />
                )}
              </ImageContainer>
            </Box>
          </Box>
        </Box>
        <Box mt={2}></Box>

        {/* image */}
        {/* <Box mt={4} mb={4}>
          <input
            accept="image/*"
            name="image"
            type="file"
            onChange={handleImageChange}
          />
          {imageUrl && selectedImage && (
            <img
              src={imageUrl}
              alt="no image found"
              style={{ width: "200px", height: "100px", marginTop: "10px" }}
            />
          )}
        </Box> */}
      </Box>
      {/* apply discount */}
      <Box sx={{ display: "flex", alignItems: "center", ml: -1.3, mt: 2 }}>
        <Checkbox
          {...label}
          size="small"
          checked={applyDiscount}
          onChange={(e) => setApplyDiscount(e.target.checked)}
        />
        <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}>
          Apply Discount
        </Typography>
        {applyDiscount && (
          <Box ml={2}>
            <TextField
              sx={{ width: "200px" }}
              value={discountValue}
              onChange={handleDiscountValueChange}
              variant="outlined"
              size="small"
              placeholder="apply discount price"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {/* <Box ml={20}>
          <InventoryComponents />
        </Box> */}
      </Box>

      {/* description */}
      <Box mt={3}>
        <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}>
          Description{" "}
        </Typography>
        <TextField
          fullWidth
          multiline
          focused
          color="secondary"
          placeholder="write description here...."
          sx={{
            width: "50%",
            mt: 0.5,
          }}
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
        />
      </Box>

      {/* ingredients */}
      <Box mt={4}>
        <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}>
          Ingredients{" "}
        </Typography>
        <div>
          <Box
            height="30%" // Each ingredient occupies 25% of the container width
            sx={{
              width: "100%",
            }}
            display="grid"
            gridTemplateColumns="repeat(2,0.1fr)"
            gridGap={1}
            gridAutoFlow
          >
            {ingredients.map((ingredient, index) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="caption">{index + 1}.</Typography>
                <Typography variant="caption">{ingredient}</Typography>
              </Box>
            ))}
            <img
              onClick={handleOpen}
              src="/plus-icon.png"
              alt=""
              width={16}
              style={{ marginRight: 14, cursor: "pointer" }}
            />
          </Box>
        </div>
      </Box>

      {/* parameters */}
      <Box mt={4}>
        <Typography
          variant="subtitle1"
          sx={{ color: "#7B029E", fontWeight: "bold" }}
        >
          Parameters{" "}
        </Typography>
        <div>
          <Box
            height="30%" // Each ingredient occupies 25% of the container width
            sx={{
              width: "100%",
            }}
            display="grid"
            gridTemplateColumns="repeat(2,0.12fr)"
            gridGap={1}
            gridAutoFlow
          >
            {Object.entries(parameter).map(([key, value]) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#7B029E", fontWeight: "bold", mr: 1.5 }}
                >
                  {key}
                </Typography>
                <Typography variant="subtitle2">:{value}</Typography>
              </Box>
            ))}
            <img
              onClick={handleOpenParameter}
              src="/plus-icon.png"
              alt=""
              width={16}
              style={{
                marginRight: 14,
                cursor: "pointer",
                marginTop: 5,
                marginLeft: 0,
              }}
            />
          </Box>
        </div>
      </Box>
      {/* buttons */}
      <Box mt={5} sx={{ display: "flex", width: "30%" }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCancel}
          sx={{
            textTransform: "capitalize",
            borderRadius: 2,
            background: "none",
            border: "1px solid #B8B6B6 ",
            color: "#7B029E",
            "&:hover": {
              background: "#FFFFFF",
            },
            boxShadow: "none",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSaveChanges}
          sx={{
            background: "#7B029E",
            ml: 2,
            textTransform: "capitalize",
            color: "#FFFFFF",
            borderRadius: 2,
            "&:hover": {
              background: "#7B029E",
            },
          }}
        >
          Save
        </Button>
      </Box>
      {/* ingredient modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Ingredients onCloseEvent={handleClose} />
      </Modal>
      {/* parameters modal */}
      <Modal
        open={openParameter}
        onClose={handleCloseParameter}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Parameters onCloseEvent={handleCloseParameter} />
      </Modal>
    </div>
  );
}

export default AddProductComponent;

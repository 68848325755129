import ScrollToTop from "react-scroll-to-top";

function ScrollToTopNew() {
  return (
    <div>
      <ScrollToTop
        smooth
        color="white"
        height="16"
        style={{ background: "#7B029E" }}
      />
    </div>
  );
}

export default ScrollToTopNew;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Chip, Divider, TablePagination, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import { API_PROD } from "../../apiUtils";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EAECF0",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OrderStatusTable() {
  const [orderStatus, setOrderStatus] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchOrderStatus = async () => {
    try {
      const response = await axios.get(API_PROD + "/order/getVendorAllOrders", {
        headers,
      });
      console.log("orderstatus", response.data.vendorOrders);
      setOrderStatus(response?.data?.vendorOrders);
      //   setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      //   setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchOrderStatus();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 900 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">
              Status
              <i
                class="fa-solid fa-arrow-down fa-sm"
                style={{ marginLeft: 2 }}
              ></i>
            </StyledTableCell>
            <StyledTableCell align="center">Orders</StyledTableCell>
            <StyledTableCell align="center">
              Amount <i class="fa-solid fa-arrow-down fa-sm"></i>
            </StyledTableCell>
            <StyledTableCell align="center">Payment method</StyledTableCell>
            <StyledTableCell align="center">Order status</StyledTableCell>
            {/* <StyledTableCell align="center"></StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderStatus
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell component="th" scope="row" align="center">
                  <Typography variant="subtitle2"> {row.user.name}</Typography>
                  <Typography variant="caption">
                    {row._id.slice(2, 8)}
                  </Typography>
                </StyledTableCell>

                {/* <StyledTableCell component="th" scope="row" align='center' sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Avatar sx={{ width: 40, height: 40, mr: 1 }}><img src='Avatar.png' alt='' /></Avatar>
                                <Box><Typography> {row.name}</Typography>
                                    <Box sx={{ display: "flex" }}><Typography>ID:</Typography>
                                        <Typography sx={{ color: "#7B029E", fontWeight: "bold" }}> {row.id}</Typography></Box>
                                </Box>
                            </StyledTableCell> */}

                {row.paymentInfo.status === "paid" ? (
                  <StyledTableCell align="center">
                    {" "}
                    <Chip
                      sx={{
                        backgroundColor: "#ECFDF3",
                        color: "#027A48",
                        fontWeight: "bold",
                      }}
                      icon={
                        <FiberManualRecordIcon
                          color="success"
                          sx={{ width: "10px" }}
                        />
                      }
                      label={row.paymentInfo.status}
                    />
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {row.paymentInfo.status === "refund" ? (
                  <StyledTableCell align="center">
                    {" "}
                    <Chip
                      sx={{
                        backgroundColor: "#FDECEC",
                        color: "#7A0202",
                        fontWeight: "bold",
                      }}
                      icon={
                        <FiberManualRecordIcon
                          color="error"
                          sx={{ width: "10px" }}
                        />
                      }
                      label={row.paymentInfo.status}
                    />
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {row.paymentInfo.status === "pending" ? (
                  <StyledTableCell align="center">
                    {" "}
                    <Chip
                      sx={{
                        backgroundColor: "#FDF8EC",
                        color: "#7A4302",
                        fontWeight: "bold",
                      }}
                      icon={
                        <FiberManualRecordIcon
                          color="warning"
                          sx={{ width: "10px" }}
                        />
                      }
                      label={row?.paymentInfo.status}
                    />
                  </StyledTableCell>
                ) : (
                  ""
                )}
                <StyledTableCell align="center">
                  {row?.products?.map((i) => (
                    <Chip
                      sx={{
                        backgroundColor: "#F9F5FF",
                        color: "#6941C6",
                        fontWeight: "bold",
                      }}
                      label={i.product.name}
                    />
                  ))}
                </StyledTableCell>

                {row.paymentInfo.status === "paid" ? (
                  <StyledTableCell align="center">
                    {" "}
                    <Chip
                      sx={{
                        backgroundColor: "#ECFDF3",
                        color: "#027A48",
                        fontWeight: "bold",
                      }}
                      label={`€ ${row?.orderPrice}`}
                    />
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {row.paymentInfo.status === "refund" ? (
                  <StyledTableCell align="center">
                    {" "}
                    <Chip
                      sx={{
                        backgroundColor: "#FDECEC",
                        color: "#B71212",
                        fontWeight: "bold",
                      }}
                      label={`€ ${row?.orderPrice}`}
                    />
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {row.paymentInfo.status === "pending" ? (
                  <StyledTableCell align="center">
                    {" "}
                    <Chip
                      sx={{
                        backgroundColor: "#FDF8EC",
                        color: "#7A4302",
                        fontWeight: "bold",
                      }}
                      label={`€ ${row?.orderPrice}`}
                    />
                  </StyledTableCell>
                ) : (
                  ""
                )}

                <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                  {row.paymentInfo.paymentMethod}
                </StyledTableCell>

                <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                  <Chip
                    sx={{
                      backgroundColor:
                        row.status === "notaccepted" ? "#FFCDD2" : "#FFEFFC",
                      color:
                        row.status === "notaccepted" ? "#FF1744" : "#6941C6",
                      fontWeight: "bold",
                    }}
                    label={
                      row.status === "orderpickedup"
                        ? "order picked"
                        : row.status && row.status === "notaccepted"
                        ? "not accepted"
                        : row.status
                    }
                  />
                </StyledTableCell>
                {/* {row.status === "Ready to pick up" && (
                <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                  <Chip
                    sx={{
                      backgroundColor: "#FFEFFC",
                      color: "#6941C6",
                      fontWeight: "bold",
                    }}
                    label={row?.contact}
                  />
                </StyledTableCell>
              )} */}
                {/* <StyledTableCell sx={{ fontWeight: "bold", color: "#FF0000" }}>
                Cancel Order
              </StyledTableCell> */}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={orderStatus.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Divider />
    </TableContainer>
  );
}

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon,
  Storage as StorageIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  MonetizationOn as MonetizationOnIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import ProfileMenu from "../profileMenu/ProfileMenu";
import SearchField from "../searchfield/SearchField";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const menuItems = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  { text: "My Orders", icon: <ShoppingCartIcon />, path: "/myOrders" },
  { text: "Inventory Management", icon: <StorageIcon />, path: "/inventory" },
  {
    text: "My Transactions",
    icon: <AccountBalanceWalletIcon />,
    path: "/myTransaction",
  },
  {
    text: "My Settlements",
    icon: <MonetizationOnIcon />,
    path: "/mySettlements",
  },
  { text: "My Profile", icon: <PersonIcon />, path: "/myProfile" },
  // { text: "Setting", icon: <SettingsIcon />, path: "" },
  {
    text: "Logout",
    icon: <ExitToAppIcon />,
    path: "/",
  },
];

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #DCDCDC;",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Set the selected item based on the current location
    const path = location.pathname;
    const item = menuItems.find((item) => item.path === path);
    setSelectedItem(item);
  }, [location]);

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    if (item.path === "/") {
      // Perform logout action
      // For example, clear user session, remove tokens, etc.
      // You can replace the following line with your logout logic
      localStorage.removeItem("accessToken");
      toast.success("Logged out successfully.");
    } else {
      navigate(item.path);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />{" "}
          {/* Added this box to push the profile menu to the end */}
          <ProfileMenu />
          {/* 
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchField />
            <Badge
                color="error"
                overlap="circular"
                badgeContent=" "
                variant="dot"
                sx={{ ml: 6, mr: 2 }}
              >
                <NotificationsIcon
                  color="action"
                  sx={{ width: "30px", mr: "-5px" }}
                />
              </Badge>
          
           
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ sx: { backgroundColor: "#6F1998" } }}
      >
        <DrawerHeader>
          <Box
            onClick={() => navigate("/dashboard")}
            sx={{ marginRight: { xs: "50px", sm: "60px" }, cursor: "pointer" }}
          >
            <img src="boto-svg.svg" alt="" />
            <img src="logo-svg.svg" alt="" />
          </Box>
          {/* <IconButton onClick={handleDrawerClose}>
                          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                      </IconButton> */}
        </DrawerHeader>

        <List sx={{ mt: 3, m: 1 }}>
          {menuItems.map((item, index) => (
            <ListItem
              key={item.text}
              disablePadding
              sx={{
                display: "block",
                backgroundColor:
                  selectedItem === item ? "#FFCC03" : "transparent",
                borderRadius: 3,
              }}
              onClick={() => handleListItemClick(item)}
            >
              <Link
                to={item.path} // Replace 'item.path' with the desired path
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 1.5,
                    color: "#FFFFFF",
                    borderRadius: 3,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      color: selectedItem === item ? "#263238" : "#FFFFFF",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,

                      color: selectedItem === item ? "#263238" : "#FFFFFF",
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}

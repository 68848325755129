import { createTheme, responsiveFontSizes } from "@mui/material";
import { green } from "@mui/material/colors";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FFC100",
    },
    secondary: {
      main: "#B8B6B6",
    },
    success: {
      main: "#5EFB44",
    },
  },
  typography: {
    fontFamily: ["Outfit", "sans-serif"].join(","),

    h1: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
    },
    h2: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
    },
    h3: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
    },
    h4: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
    },
    h5: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
    },
    h6: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
    },
    caption: {
      fontFamily: ["Outfit", "sans-serif"].join(","),
      color: "#808080",
    },
  },
});
export default responsiveFontSizes(theme);

import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Avatar, Chip, Divider, Modal, Typography } from "@mui/material";
import Confirmation from "./ConfirmationDialog";
import { API_PROD } from "../../apiUtils";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import io from "socket.io-client";
import { useAppStore } from "../../appStore";

export default function MyOrdersTable() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  // const [singleOrder, setSingleOrder] = useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [timer, setTimer] = useState(100); // 5 minutes in seconds
  const [isOrderExpired, setIsOrderExpired] = useState(false);
  const [isMounted, setIsMounted] = useState(false); // New state for checking if component is mounted
  const setOngoingOrders = useAppStore((state) => state.setOngoingOrders);
  const ongoingOrders = useAppStore((state) => state.ongoingOrders);
  const setSingleOrder = useAppStore((state) => state.setSingleOrder);
  const singleOrder = useAppStore((state) => state.singleOrder);
  const { vendorId, setVendorId } = useAppStore();

  const handleOpen = (orderId) => {
    setSingleOrder(orderId);
    setOpen(true);
  };
  const handleClose = () => {
    setSingleOrder("");
    setOpen(false);
  };

  const tokenStr = localStorage.getItem("accessToken");
  const headers = {
    accessToken: tokenStr,
  };

  const fetchOrders = async (e) => {
    try {
      const response = await axios.get(API_PROD + "/order/getVendorOrders", {
        headers,
      });
      // console.log("orders", response.data.vendorOrders);
      setOrders(response?.data?.vendorOrders);
      setIsLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  // const startTimer = () => {
  //   const interval = setInterval(() => {
  //     setTimer((prevTimer) => prevTimer - 1);
  //   }, 1000);
  //   return interval;
  // };

  React.useEffect(() => {
    const socket = io("http://13.51.255.37:5000"); // Replace with your server URL

    socket.on("connect", () => {
      console.log("Connected to server:", socket.id);
      socket.emit("joinVendorRoom", { vendorId: vendorId });
      // Replace with the actual vendor ID
    });

    socket.on("newOrder", (newOrder) => {
      console.log("New order received:", newOrder);
      setOrders(newOrder);
      setIsLoading(false);
    });

    setIsMounted(true); // Set isMounted to true on component mount

    // Timer logic
    // if (isMounted) {
    //   // Check if component is mounted
    //   const interval = startTimer();
    //   return () => {
    //     clearInterval(interval);
    //   };
    // }

    // return () => {
    //   socket.disconnect(); // Clean up the socket connection when the component is unmounted
    // };

    // fetchOrders();
  }, [isMounted]);

  // useEffect(() => {
  //   if (timer === 0) {
  //     setIsOrderExpired(true);
  //     // localStorage.setItem("isOrderExpired", "true");
  //   }
  // }, [timer]);

  // console.log("ofresrd", orders);

  const fetchOngoingOrders = async () => {
    try {
      const response = await axios.get(
        API_PROD + "/order/vendorOngoingOrders",
        {
          headers,
        }
      );
      // console.log("ongogingorders", response.data);
      setOngoingOrders(response?.data?.ongoingOrders);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  // accept orders
  const handleAccept = async (id) => {
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    try {
      const response = await axios.put(
        API_PROD + `/order/updateOrder/${id}`,
        { status: "accepted" },
        { headers }
      );
      toast.success("Order Accepetd.");
      // Remove the accepted order from the orders state array
      setOrders((prevOrders) => prevOrders.filter((order) => order._id !== id));
      fetchOngoingOrders();
      fetchOrders();
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    }
    // navigate("/dashboard");
  };

  //denied
  const handleDenied = async (id) => {
    let tokenStr = localStorage.getItem("accessToken");
    const headers = {
      accessToken: tokenStr,
    };
    try {
      const response = await axios.put(
        API_PROD + `/order/updateOrder/${id}`,
        { status: "notaccepted" },
        { headers }
      );
      toast.success("You have denied the order.");
      // Update the status of the denied order in the orders state array
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === id ? { ...order, status: "notaccepted" } : order
        )
      );
      fetchOngoingOrders();
    } catch (error) {
      // Handle error case
      toast.error(error.message);
    }
    // navigate("/dashboard");
  };

  return (
    <>
      {isLoading ? (
        <Box mb={2}>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
              width: "auto",
              height: "auto",
              border: "1px solid #CCCCCC",
              borderRadius: 6,
            },
          }}
        >
          <Paper variant="outlined">
            {orders
              ?.filter((row) => row.status !== "accepted")
              .map((row, index) => {
                if (row.status === "accepted") {
                  return null; // Skip rendering the row if the status is "accepted"
                }
                return (
                  <Box p={1}>
                    <Box sx={{ display: "flex", padding: 1 }}>
                      <Typography>{index + 1}.</Typography>
                      <Box>
                        <Typography ml={0.5}>
                          {" "}
                          {row.products?.map((i) => (
                            <Chip
                              size="small"
                              sx={{
                                backgroundColor: "#F9F5FF",
                                color: "#6941C6",
                                fontWeight: "bold",
                              }}
                              label={i?.product?.name}
                            />
                          ))}
                        </Typography>
                        <Typography variant="caption" sx={{ color: "#808080" }}>
                          Order pickup time 11:00 Am{" "}
                        </Typography>
                      </Box>
                      {/* 2nd row */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          ml: 4,
                        }}
                      >
                        <Avatar sx={{ width: 38, height: 38, mr: 1 }}>
                          <img src="Ellipse-9.png" alt="" />
                        </Avatar>
                        <Box>
                          <Typography variant="subtitle2" sx={{ mt: "-4px" }}>
                            {" "}
                            {row?.user?.name}
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle2">ID:</Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#7B029E", fontWeight: "bold" }}
                            >
                              {/* needs to be change according to client requirement */}
                              {row._id.slice(2, 8)}
                              {/* 1234545 */}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {/* 3rd row */}
                      <Box sx={{ ml: 3 }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="subtitle2">Phone:</Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#000000", ml: 1 }}
                          >
                            {" "}
                            {row?.user?.phonenumber}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="subtitle2">Email:</Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#000000", ml: 1 }}
                          >
                            {" "}
                            {row?.user?.email}
                          </Typography>
                        </Box>
                      </Box>

                      {/* 4th row */}
                      <Box sx={{ ml: 3 }}>
                        <Typography
                          sx={{ color: "#000000", textAlign: "center" }}
                        >
                          {" "}
                          € {row.orderPrice}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#000000" }}
                        >
                          {" "}
                          Payment in online mode
                          {/* {row.paymentmode} */}
                        </Typography>
                      </Box>

                      {/* timming */}
                      {/* <Box sx={{ ml: 2 }}>
                        {isOrderExpired ? (
                          ""
                        ) : (
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#000000" }}
                          >
                            Timer:{" "}
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "red",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "bold",
                            ml: 1,
                          }}
                        >
                          {isOrderExpired
                            ? "Order Expired"
                            : `${Math.floor(timer / 60)}:${timer % 60}`}
                        </Typography>
                      </Box> */}

                      {/* 5th row */}
                      {isOrderExpired ? (
                        ""
                      ) : (
                        <Box
                          onClick={() => handleDenied(row._id)}
                          sx={{ ml: 3, textAlign: "center", cursor: "pointer" }}
                        >
                          <Typography variant="subtitle2">
                            <img src="cross.png" width={25} alt="" />
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#808080", fontSize: "12px" }}
                          >
                            Cancel
                          </Typography>{" "}
                        </Box>
                      )}
                      {/* 6th row */}
                      {isOrderExpired ? (
                        ""
                      ) : (
                        <Box
                          onClick={() => handleAccept(row._id)}
                          sx={{
                            ml: 2,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="subtitle2">
                            <img src="tick.png" width={25} alt="" />
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#808080", fontSize: "12px" }}
                          >
                            Accept
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Divider />
                  </Box>
                );
              })}

            {orders?.filter((order) => order.status !== "accepted").length ===
              0 && (
              <Box p={1} textAlign="center">
                <Typography variant="subtitle1">No orders found.</Typography>
              </Box>
            )}

            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Confirmation onCloseEvent={handleClose} />
            </Modal> */}
          </Paper>
        </Box>
      )}
    </>
  );
}
